.prime-nav,
.prime-nav *,
.prime-nav *:before,
.prime-nav *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

body {
  background-position: center;
  font-family: Open Sans, sans-serif;
  font-size: 13px;
  -ms-overflow-x: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #f2f2f2;
  line-height: 20px; }

h1 {
  text-rendering: optimizeSpeed; }

h2 {
  text-rendering: optimizeSpeed; }

h3 {
  text-rendering: optimizeSpeed; }

h4 {
  text-rendering: optimizeSpeed; }

h5 {
  text-rendering: optimizeSpeed; }

h6 {
  text-rendering: optimizeSpeed; }

.wp-caption {
  text-align: center;
  max-width: 100%; }

.container-fluid {
  padding-left: 0;
  padding-right: 0; }

.row {
  margin-left: 0;
  margin-right: 0; }

.span12 {
  padding-left: 0;
  padding-right: 0; }

.span6 {
  padding-left: 0;
  padding-right: 0; }

.span1 {
  padding-right: 15px; }

.wp-caption-text {
  margin: 8px 0 6px;
  text-align: center; }

#wpadminbar * {
  color: #cccccc !important; }

#wpadminbar .hover a {
  color: #464646 !important; }

#wpadminbar .hover a span {
  color: #464646 !important; }

.alignleft {
  margin: 0 20px 18px 0;
  float: left; }

.alignright {
  margin: 0 0 18px 20px;
  float: right; }

.alignleft {
  padding: 6px;
  display: inline-block;
  background-color: white; }

.alignright {
  padding: 6px;
  display: inline-block;
  background-color: white; }

.aligncenter {
  padding: 6px;
  display: inline-block;
  background-color: white; }

.aligncenter {
  margin: 18px auto;
  clear: both;
  display: block; }

body a {
  color: black;
  text-decoration: none; }

body a:hover {
  color: black;
  text-decoration: none; }

body a:hover {
  color: #ed7721; }

::selection {
  background: #ed7721;
  color: white; }

.bluebox-wrap {
  margin: 0 auto;
  max-width: 1500px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: white;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -webkit-backface-visibility: hidden; }

.bluebox-container {
  margin: 0 auto;
  max-width: 1020px; }

body.no-sidebars .bluebox-content-wrap > .bluebox-container {
  max-width: 100%; }

.bluebox-clear {
  width: 100%;
  height: 0;
  clear: both; }

.bluebox-button {
  padding: 0 50px 0 15px;
  height: 35px;
  color: white !important;
  line-height: 35px;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-button-link {
  padding: 0 50px 0 15px;
  height: 35px;
  color: white !important;
  line-height: 35px;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.tp-caption a {
  color: white !important; }

.large.bluebox-button {
  padding: 0 65px 0 20px;
  height: 45px;
  line-height: 45px; }

.light.bluebox-button {
  color: black !important;
  background-color: rgba(0, 0, 0, 0.02); }

.light.bluebox-button:hover {
  color: black !important;
  background-color: rgba(0, 0, 0, 0.02); }

.bluebox-button:hover {
  color: white;
  background-color: #ed7721; }

.roki-button-link:hover {
  color: white;
  background-color: #ed7721; }

.extra.bluebox-button::before {
  top: 0;
  width: 35px;
  height: 35px;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  z-index: 1;
  content: "";
  background-color: rgba(0, 0, 0, 0.04); }

.roki-button-link::before {
  top: 0;
  width: 35px;
  height: 35px;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  z-index: 1;
  content: "";
  background-color: rgba(0, 0, 0, 0.04); }

.extra.bluebox-button span {
  top: 0;
  width: 35px !important;
  height: 35px;
  text-align: center;
  right: 0;
  bottom: 0;
  color: white;
  line-height: 36px;
  font-size: 10px;
  display: block;
  position: absolute;
  z-index: 1; }

.roki-button-link span {
  top: 0;
  width: 35px !important;
  height: 35px;
  text-align: center;
  right: 0;
  bottom: 0;
  color: white;
  line-height: 36px;
  font-size: 10px;
  display: block;
  position: absolute;
  z-index: 1; }

.extra.large.bluebox-button span {
  width: 45px !important;
  height: 45px;
  line-height: 46px; }

.extra.bluebox-button span > span {
  display: none; }

.extra.large.bluebox-button::before {
  width: 45px;
  height: 45px;
  content: "";
  background-color: rgba(0, 0, 0, 0.04); }

.extra.light.bluebox-button span {
  color: rgba(0, 0, 0, 0.26); }

.bluebox-info-bar {
  padding: 7px 0 8px;
  color: white;
  min-height: 20px;
  background-color: #ed7721; }

.light.bluebox-info-bar {
  padding: 7px 0 8px;
  color: rgba(0, 0, 0, 0.5);
  min-height: 20px;
  background-color: rgba(0, 0, 0, 0.02); }

.bluebox-head-top {
  min-height: 105px; }

.bluebox-head-top .bluebox-head-logo {
  float: left; }

.bluebox-head-top .bluebox-head-logo a {
  margin-top: 18px;
  float: left; }

.bluebox-head-top .bluebox-head-logo a img {
  display: inline-block; }

.bluebox-head-details {
  margin-top: 30px;
  float: right; }

.bluebox-details-tagline {
  height: 45px;
  float: right; }

.bluebox-details-search {
  height: 45px;
  float: right; }

.bluebox-details-social {
  height: 45px;
  float: right; }

.bluebox-details-search {
  position: relative; }

.bluebox-details-tagline {
  padding-right: 20px; }

.bluebox-details-social a {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  text-decoration: none;
  float: right;
  display: block;
  background-color: rgba(0, 0, 0, 0.02); }

.bluebox-details-social a.bluebox-shadows {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-details-social a:last-child.bluebox-shadows {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-head-bottom {
  min-height: 60px; }

.bluebox-prime-nav {
  position: relative; }

.bluebox-prime-nav #prime-nav-searchform {
  width: 348px;
  height: 60px;
  right: 0;
  bottom: -60px;
  color: white;
  display: block;
  position: absolute;
  z-index: 211;
  background-color: #ed7721; }

.bluebox-prime-nav #prime-nav-searchform input.submit {
  display: none; }

.bluebox-prime-nav #prime-nav-searchform form {
  display: block;
  position: relative; }

.bluebox-prime-nav #prime-nav-searchform i {
  left: 24px;
  top: 24px;
  color: rgba(255, 255, 255, 0.5);
  position: absolute; }

.bluebox-prime-nav #prime-nav-searchform form input[type='text'].bluebox-search-input {
  padding: 12px 20px 12px 60px;
  width: 268px;
  height: 36px;
  color: white;
  line-height: 36px;
  font-size: 18px;
  font-weight: 300; }

.icon-remove.searchform-icon {
  cursor: pointer; }

.prime-nav-searchform-button-active {
  color: white;
  background-color: #ed7721; }

.prime-nav-searchform-button-active:hover {
  background-color: transparent; }

#prime-nav-searchform {
  visibility: hidden; }

.active#prime-nav-searchform {
  visibility: visible; }

.type-two.bluebox-header .bluebox-head-details {
  display: none; }

.type-two.bluebox-header .bluebox-clear {
  display: none; }

.type-two.bluebox-header .bluebox-head-top {
  min-height: 120px; }

.type-two.bluebox-header .bluebox-head-bottom {
  display: none; }

.type-two.bluebox-header ul.prime-nav {
  width: inherit;
  margin-top: 30px;
  float: right; }

.nav-style-one.type-two.bluebox-header ul.prime-nav {
  padding: 0;
  display: block;
  list-style-type: none;
  min-height: 60px;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-two.type-two.bluebox-header ul.prime-nav {
  background-color: rgba(0, 0, 0, 0.74); }

.type-two.bluebox-header ul.prime-nav > li.search {
  float: left; }

.type-two.bluebox-header ul.prime-nav > li.search a {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-style-one.type-two.bluebox-header ul.prime-nav > li.search a {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-two.type-two.bluebox-header ul.prime-nav > li.search a {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-three.type-two.bluebox-header ul.prime-nav > li:last-child.search::after {
  content: ""; }

.nav-style-three.type-two.bluebox-header ul.prime-nav > li a:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-style-four.type-two.bluebox-header ul.prime-nav > li a:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.type-centered.bluebox-header ul.prime-nav {
  text-align: center; }

.type-centered.bluebox-header ul.prime-nav > li {
  float: none; }

.nav-style-one.type-centered.bluebox-header ul.prime-nav > li.search a {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-two.type-centered.bluebox-header ul.prime-nav > li.search a {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08);
  -moz-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08); }

ul.prime-nav {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  list-style-type: none;
  min-height: 60px; }

ul.prime-nav > li > ul.sub-menu.bluebox-dropdown > li ul.sub-menu.bluebox-dropdown {
  left: 100%;
  top: -4px; }

ul.prime-nav > li {
  text-align: left;
  float: left;
  display: inline-block;
  position: relative;
  z-index: 1100; }

ul.prime-nav > li .menu-item {
  position: relative; }

ul.prime-nav > li > a {
  padding: 10px 20px;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  display: inline-block; }

ul.prime-nav > li.search {
  float: right; }

ul.prime-nav > li.search a {
  padding: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  display: block; }

.nav-style-one .bluebox-head-bottom {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.nav-style-one ul.prime-nav > li.search {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-one ul.prime-nav > li {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-one ul.prime-nav > li:first-child {
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-one ul.prime-nav > li > a {
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300; }

.nav-style-one ul.prime-nav > li > a:hover {
  box-shadow: inset 0 -4px 0 0 #ed7721;
  -webkit-box-shadow: inset 0 -4px 0 0 #ed7721;
  -moz-box-shadow: inset 0 -4px 0 0 #ed7721; }

.nav-style-one ul.prime-nav > li.search {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-style-one ul.prime-nav > li.search a {
  padding: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  display: block;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-two .bluebox-head-bottom {
  box-shadow: inset 0 -4px 0 0 rgba(255, 255, 255, 0.03);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 -4px 0 0 rgba(255, 255, 255, 0.03);
  -moz-box-shadow: inset 0 -4px 0 0 rgba(255, 255, 255, 0.03); }

.nav-style-two ul.prime-nav > li.search {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08);
  -moz-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08); }

.nav-style-two ul.prime-nav > li {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08);
  -moz-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08); }

.nav-style-two ul.prime-nav > li:first-child {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08);
  -moz-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08); }

.nav-style-two ul.prime-nav > li > a {
  padding: 10px 20px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300; }

.nav-style-two ul.prime-nav > li > a:hover {
  color: #ed7721;
  box-shadow: inset 0 -4px 0 0 #ed7721;
  -webkit-box-shadow: inset 0 -4px 0 0 #ed7721;
  -moz-box-shadow: inset 0 -4px 0 0 #ed7721; }

.nav-style-two ul.prime-nav > li.search {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-style-two ul.prime-nav > li.search > a {
  padding: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  display: block;
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08);
  -moz-box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), inset 1px 0 0 0 rgba(255, 255, 255, 0.08); }

.nav-style-three .bluebox-head-bottom {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-three ul.prime-nav > li {
  padding: 0 20px;
  position: relative; }

.nav-style-three ul.prime-nav > li:first-child {
  padding-left: 0; }

.nav-style-three ul.prime-nav > li::after {
  top: 20px;
  right: -2px;
  color: rgba(0, 0, 0, 0.16);
  position: absolute;
  content: "/"; }

.nav-style-three ul.prime-nav > li:last-child::after {
  content: "/"; }

.nav-style-three ul.prime-nav > li > a {
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300; }

.nav-style-three ul.prime-nav > li > a:hover {
  box-shadow: inset 0 -4px 0 0 #ed7721;
  -webkit-box-shadow: inset 0 -4px 0 0 #ed7721;
  -moz-box-shadow: inset 0 -4px 0 0 #ed7721; }

.nav-style-three ul.prime-nav > li.search {
  padding: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-style-three ul.prime-nav > li.search > a {
  padding: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  display: block; }

.nav-style-three ul.prime-nav > li.search > a:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-style-four .bluebox-head-bottom {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06); }

.nav-style-four ul.prime-nav > li {
  padding: 0 12px;
  position: relative; }

.nav-style-four ul.prime-nav > li:first-child {
  padding-left: 0; }

.nav-style-four ul.prime-nav > li > a {
  padding: 10px 0;
  font-size: 14px;
  font-weight: 300; }

.nav-style-four ul.prime-nav > li > a:hover {
  box-shadow: inset 0 -4px 0 0 #ed7721;
  -webkit-box-shadow: inset 0 -4px 0 0 #ed7721;
  -moz-box-shadow: inset 0 -4px 0 0 #ed7721; }

.nav-style-four ul.prime-nav > li.search {
  padding: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-style-four ul.prime-nav > li.search a {
  padding: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  display: block; }

.nav-style-four ul.prime-nav > li.search a:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

ul.prime-nav li > ul.bluebox-dropdown {
  margin: 0;
  padding: 0;
  top: 56px;
  border-top-color: #ed7721;
  border-top-width: 4px;
  border-top-style: solid;
  display: none;
  list-style-type: none;
  position: absolute;
  z-index: 210;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06);
  background-color: #f9f9f9;
  -webkit-box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06);
  text-wrap: none; }

ul.prime-nav li:hover > ul.bluebox-dropdown {
  display: block;
  min-width: 200px; }

ul.prime-nav li > ul.bluebox-dropdown li {
  display: block;
  min-height: 35px;
  text-wrap: none; }

ul.prime-nav li > ul.bluebox-dropdown li a {
  padding: 7px 20px 8px;
  border-bottom-color: #ebebeb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: relative;
  text-wrap: none; }

ul.prime-nav li > ul.bluebox-dropdown li a > i {
  top: 13px;
  right: 10px;
  font-size: 8px;
  position: absolute;
  opacity: 0.5; }

ul.prime-nav li > ul.bluebox-dropdown li a:hover > i {
  opacity: 1; }

ul.prime-nav li > ul.bluebox-dropdown li a:hover {
  color: #ed7721;
  background-color: white; }

ul.prime-nav li > ul.bluebox-dropdown li.current-menu-item > a {
  color: #ed7721;
  background-color: white; }

ul.prime-nav li > ul.bluebox-dropdown li:last-child > a:first-child {
  border-bottom-color: currentColor;
  border-bottom-width: medium;
  border-bottom-style: none; }

.dark-dropdown.bluebox-header ul.prime-nav li > ul.bluebox-dropdown {
  background-color: rgba(0, 0, 0, 0.74); }

.dark-dropdown.bluebox-header ul.prime-nav li > ul.bluebox-dropdown li a {
  color: white;
  border-bottom-color: #494949;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.dark-dropdown.bluebox-header ul.prime-nav li > ul.bluebox-dropdown li a:hover {
  color: #ed7721;
  background-color: rgba(255, 255, 255, 0.04); }

.dark-dropdown.bluebox-header ul.prime-nav li > ul.bluebox-dropdown li.current-menu-item > a {
  color: #ed7721;
  background-color: rgba(255, 255, 255, 0.04); }

.page-heading-wrap {
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  min-height: 105px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.page-heading-wrap .bluebox-container {
  position: relative; }

.page-heading-wrap h2 {
  margin: 30px 0;
  width: 70%;
  text-transform: uppercase;
  line-height: 24px;
  font-size: 18px;
  display: inline-block; }

.page-heading-wrap h2 > span {
  color: rgba(0, 0, 0, 0.5);
  text-transform: none;
  font-size: 13px;
  font-weight: 400;
  margin-left: 16px; }

.page-heading-wrap h2 > span::before {
  color: rgba(0, 0, 0, 0.3);
  margin-right: 20px;
  content: "/"; }

.page-heading-wrap .bluebox-heading-details {
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: 5px;
  position: absolute; }

.page-heading-wrap .bluebox-heading-details .bluebox-breadcrumbs {
  float: right; }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-search {
  float: right; }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-social {
  float: right; }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-arrows {
  float: right; }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-social {
  margin-top: -12px; }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-search {
  margin-top: -12px; }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-arrows {
  margin-top: -12px; }

.page-heading-wrap .bluebox-heading-details .bluebox-breadcrumbs {
  margin-left: 20px; }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-search {
  margin-right: -1px;
  position: relative; }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-search > i {
  left: 14px;
  top: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  position: absolute; }

.bluebox-heading-search input.submit {
  display: none; }

.bluebox-details-search input.submit {
  display: none; }

.bluebox-heading-search i {
  left: 15px;
  top: 15px;
  position: absolute; }

.bluebox-details-search i {
  left: 15px;
  top: 15px;
  position: absolute; }

.bluebox-details-search input[type='text'].bluebox-search-input {
  margin-right: -1px; }

input[type='text'].bluebox-search-input {
  margin: 0;
  padding: 12px 20px 12px 40px;
  border-radius: 0;
  border: currentColor !important;
  border-image: none !important;
  width: 160px;
  height: 21px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08); }

input[type='text'].bluebox-search-input:focus {
  border-radius: 0;
  border: currentColor !important;
  border-image: none !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08); }

.bluebox-heading-arrows a.bluebox-shadows {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  text-decoration: none;
  float: right;
  display: block;
  background-color: rgba(0, 0, 0, 0.02); }

.bluebox-heading-social a.bluebox-shadows {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  text-decoration: none;
  float: right;
  display: block;
  background-color: rgba(0, 0, 0, 0.02); }

.bluebox-heading-arrows a.bluebox-shadows {
  color: rgba(0, 0, 0, 0.26); }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-arrows a.bluebox-shadows {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-social a.bluebox-shadows {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-arrows a:last-child.bluebox-shadows {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06); }

.page-heading-wrap .bluebox-heading-details .bluebox-heading-social a:last-child.bluebox-shadows {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-shadows {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-style-one {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-bottom-shadow {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-top-n-bottom-shadow {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-top-n-bottom-plus-detail-shadow {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.bluebox-right-shadow {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-slider-wrap {
  height: 400px;
  position: relative; }

.bluebox-slider-content {
  height: 100%;
  position: relative;
  z-index: 0; }

.bluebox-slider-content .slide-details {
  top: 60px;
  width: 37%;
  right: 0;
  bottom: 60px;
  position: absolute;
  z-index: 9999;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-slider-content .iosSlider-details > div.detail-item {
  display: none;
  position: static;
  opacity: 0; }

.bluebox-slider-content .iosSlider-details > div.active.detail-item {
  display: block;
  opacity: 1; }

.bluebox-slider-content .slide-details .details-wrap {
  width: 100%;
  height: 100%;
  position: relative; }

.bluebox-slider-content .slide-details .details-wrap .details-content {
  left: 30px;
  top: 30px;
  right: 30px;
  bottom: 75px;
  color: white;
  position: absolute; }

.bluebox-slider-content .slide-details .details-wrap .prev-next-links {
  display: block; }

.bluebox-slider-content .slide-pagination {
  left: 0;
  bottom: 0;
  position: absolute; }

.bluebox-slider-content .slide-details .details-wrap .details-content h2 {
  margin: 0 0 18px;
  text-transform: uppercase;
  line-height: 30px;
  font-size: 24px; }

.bluebox-slider-content .slide-details .details-wrap .details-content p {
  color: rgba(255, 255, 255, 0.5); }

.bluebox-slider-content .slide-details .details-wrap a.bluebox-button {
  right: 0;
  bottom: 0;
  position: absolute; }

.bluebox-slider-wrap::before {
  left: 50%;
  width: 0;
  height: 0;
  bottom: 0;
  margin-left: -9px;
  border-right-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-left-color: transparent;
  border-right-width: 8px;
  border-bottom-width: 8px;
  border-left-width: 8px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 20;
  content: ""; }

.bluebox-slider-wrap::after {
  left: 50%;
  width: 0;
  height: 0;
  bottom: 0;
  margin-left: -7px;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 21;
  content: ""; }

.bluebox-slider-top-detail {
  left: 0;
  top: 0;
  height: 1px;
  right: 0;
  position: absolute;
  z-index: 18;
  background-color: rgba(0, 0, 0, 0.06); }

.bluebox-slider-bottom-detail {
  left: 0;
  height: 1px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 18;
  background-color: rgba(0, 0, 0, 0.06); }

.nova-slider-bottom-nav.bluebox-slider-wrap::before {
  display: none; }

.nova-slider-bottom-nav.bluebox-slider-wrap::after {
  display: none; }

.nova-slider-bottom-nav .bluebox-slider-content ul {
  margin: 0;
  padding: 0;
  height: 60px;
  right: 0;
  display: block; }

.nova-slider-bottom-nav .bluebox-slider-content ul li {
  height: 60px;
  margin-left: 0 !important;
  float: left;
  display: block; }

.five-tabs.nova-slider-bottom-nav .bluebox-slider-content ul li {
  width: 20% !important; }

.four-tabs.nova-slider-bottom-nav .bluebox-slider-content ul li {
  width: 25% !important; }

.two-tabs.nova-slider-bottom-nav .bluebox-slider-content ul li {
  width: 50% !important; }

.one-tab.nova-slider-bottom-nav .bluebox-slider-content ul li {
  width: 100% !important; }

.nova-slider-bottom-nav .bluebox-slider-content ul li a {
  padding: 0 20px 0 80px;
  width: auto !important;
  height: 60px;
  color: white;
  line-height: 60px;
  font-size: 18px;
  margin-right: 1px;
  display: block;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

.nova-slider-bottom-nav .bluebox-slider-content ul li a span {
  left: 0;
  top: 0;
  width: 60px;
  text-align: center;
  bottom: 0;
  color: white;
  line-height: 62px;
  font-size: 13px;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.nova-slider-bottom-nav .bluebox-slider-content ul li a:hover {
  color: white;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 #ed7721;
  background-color: rgba(0, 0, 0, 0.86);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 #ed7721;
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 #ed7721; }

.nova-slider-bottom-nav .bluebox-slider-content ul li.current a {
  height: 60px;
  color: white;
  display: block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

.nova-slider-bottom-nav .bluebox-slider-content ul li.current a:hover {
  height: 60px;
  color: white;
  display: block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

.nova-slider-bottom-nav .bluebox-slider-content ul li a:hover::after {
  left: 50%;
  width: 0;
  height: 0;
  bottom: 4px;
  margin-left: -7px;
  border-right-color: transparent;
  border-bottom-color: #ed7721;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 31;
  content: ""; }

.nova-slider-bottom-nav .bluebox-slider-content ul li.current a::after {
  left: 50%;
  width: 0;
  height: 0;
  bottom: -1px;
  margin-left: -7px;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 31;
  content: ""; }

.nova-slider-bottom-nav .bluebox-slider-content ul li.current a:hover::after {
  left: 50%;
  width: 0;
  height: 0;
  bottom: -1px;
  margin-left: -7px;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 31;
  content: ""; }

.nova-slider-bottom-nav .bluebox-slider-content ul li:last-child a {
  margin-right: 0; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details {
  bottom: auto !important;
  box-shadow: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details .details-wrap ul {
  margin: 0;
  padding: 0;
  display: block; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li {
  padding: 0;
  height: 60px;
  margin-bottom: 1px;
  display: block; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a {
  padding: 0 20px 0 84px;
  height: 60px;
  color: white;
  line-height: 60px;
  font-size: 18px;
  display: block;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a span {
  left: 0;
  top: 0;
  width: 60px;
  text-align: center;
  bottom: 0;
  color: white;
  line-height: 62px;
  font-size: 13px;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a:hover span {
  border-right-color: #ed7721;
  border-right-width: 4px;
  border-right-style: solid; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.86); }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a {
  color: white;
  background-color: #ed7721; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a:hover {
  color: white;
  background-color: #ed7721; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a span {
  border-right-color: white;
  border-right-width: 4px;
  border-right-style: solid; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a:hover span {
  border-right-color: white;
  border-right-width: 4px;
  border-right-style: solid; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a:hover::after {
  left: 64px;
  top: 50%;
  width: 0;
  height: 0;
  margin-top: -7px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #ed7721;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a::after {
  left: 64px;
  top: 50%;
  width: 0;
  height: 0;
  margin-top: -7px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: white;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a:hover::after {
  left: 64px;
  top: 50%;
  width: 0;
  height: 0;
  margin-top: -7px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: white;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

.nova-slider-four .bluebox-slider-content .extra-info.slide-details {
  left: 0;
  top: inherit;
  right: inherit;
  bottom: 0;
  position: absolute; }

.nova-slider-four .bluebox-slider-content .extra-info.slide-details .details-wrap {
  padding: 30px;
  width: auto;
  color: rgba(255, 255, 255, 0.5);
  border-left-color: #ed7721;
  border-left-width: 4px;
  border-left-style: solid; }

.nova-slider-four .bluebox-slider-content .extra-info.slide-details .details-wrap::after {
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  margin-top: -7px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #ed7721;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

.nova-slider-four .bluebox-slider-content .heading-container {
  color: white;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px;
  margin-top: 60px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.74); }

.nova-slider-four .bluebox-slider-content .heading-container > div {
  padding: 20px 84px 20px 20px;
  position: relative; }

.nova-slider-four .bluebox-slider-content .heading-container h2 {
  margin: 0;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px;
  position: relative; }

.nova-slider-four .bluebox-slider-content .bottom.heading-container {
  top: inherit !important;
  bottom: 0; }

.nova-slider-four .bluebox-slider-content .heading-container a.slide-link {
  top: 0;
  width: 60px;
  text-align: center;
  right: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.26);
  line-height: 62px;
  font-size: 24px;
  border-left-color: rgba(255, 255, 255, 0.26);
  border-left-width: 4px;
  border-left-style: solid;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.16); }

.nova-slider-four .bluebox-slider-content .heading-container a.slide-link::after {
  left: -10px;
  top: 50%;
  width: 0;
  height: 0;
  margin-top: -7px;
  border-top-color: transparent;
  border-right-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: transparent;
  border-top-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  position: absolute;
  content: ""; }

.nova-slider-four .bluebox-slider-content .heading-container a.slide-link:hover {
  color: white;
  border-left-color: #ed7721;
  border-left-width: 4px;
  border-left-style: solid;
  background-color: rgba(0, 0, 0, 0.26); }

.nova-slider-four .bluebox-slider-content .heading-container a.slide-link:hover::after {
  border-right-color: #ed7721;
  border-right-width: 6px;
  border-right-style: solid; }

.nova-slider-four .bluebox-slider-content .prev-next-links {
  left: inherit !important;
  right: 0;
  display: block; }

.rev_slider_wrapper .slotholder {
  position: relative; }

.rev_slider_wrapper::before {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

.rev_slider_wrapper::after {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

.slotholder::before {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

.slotholder::after {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

.rev_slider_wrapper::before {
  left: 0;
  top: 0;
  height: 1px;
  right: 0; }

.rev_slider_wrapper::after {
  left: 0;
  height: 1px;
  right: 0;
  bottom: 0; }

.slotholder::before {
  left: 0;
  top: 0;
  width: 1px;
  bottom: 0; }

.slotholder::after {
  top: 0;
  width: 1px;
  right: 0;
  bottom: 0; }

.tp-bannertimer {
  height: 6px !important;
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.06) !important; }

.simplebullets.tp-bullets {
  bottom: 0 !important;
  opacity: 1 !important; }

.simplebullets.tp-bullets .bullet {
  margin: 0 0 0 1px !important;
  width: 20px !important;
  height: 20px !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.26) !important;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.simplebullets.tp-bullets .bullet:hover {
  background-color: rgba(0, 0, 0, 0.74) !important; }

.simplebullets.tp-bullets .selected.bullet {
  background-color: rgba(0, 0, 0, 0.74) !important; }

.default.tparrows {
  width: 38px !important;
  height: 40px !important;
  text-align: center;
  color: rgba(0, 0, 0, 0.26) !important;
  line-height: 42px;
  font-size: 16px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06) !important;
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.06) !important;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.default.tparrows:hover {
  color: white !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06) !important;
  background-color: #ed7721 !important;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.default.tparrows.tp-leftarrow {
  padding-right: 2px !important; }

.default.tparrows.tp-rightarrow {
  width: 39px !important;
  padding-left: 1px !important; }

.round.tp-rightarrow.tparrows {
  margin-top: 0;
  margin-left: 35px !important; }

.round.tp-leftarrow.tparrows {
  margin-top: 0;
  margin-right: 35px !important; }

.revolution-slider-layout-element.gumm-layout-element {
  display: none;
  position: relative; }

.revolution-slider-layout-element.gumm-layout-element .tparrows {
  visibility: hidden; }

.revslider-gumm-initialised.revolution-slider-layout-element.gumm-layout-element .tparrows {
  visibility: visible; }

.rev-slider-spinner {
  font-size: 30px;
  display: block;
  position: absolute; }

.revolution-slider-layout-element .fullwidthbanner-container div.bottom-rokislide-detail {
  height: 20px;
  margin-bottom: -20px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.12), 0 -3px 3px 0 rgba(0, 0, 0, 0.06), 0 -6px 12px 0 rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.12), 0 -3px 3px 0 rgba(0, 0, 0, 0.06), 0 -6px 12px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.12), 0 -3px 3px 0 rgba(0, 0, 0, 0.06), 0 -6px 12px 0 rgba(0, 0, 0, 0.06); }

.header-element .rev_slider_wrapper::after {
  left: 0;
  height: 20px;
  right: 0;
  bottom: -20px;
  display: block;
  position: absolute;
  z-index: 200;
  content: " ";
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.12), 0 -3px 3px 0 rgba(0, 0, 0, 0.06), 0 -6px 12px 0 rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.12), 0 -3px 3px 0 rgba(0, 0, 0, 0.06), 0 -6px 12px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.12), 0 -3px 3px 0 rgba(0, 0, 0, 0.06), 0 -6px 12px 0 rgba(0, 0, 0, 0.06); }

.roki-rev-slide-vl-opt1-opt2 {
  padding: 10px 15px;
  color: white;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-vl-opt1-opt4 {
  padding: 10px 15px;
  color: black;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-vl-opt2-opt1 {
  padding: 10px 15px;
  color: #ed7721;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  background-color: white; }

.roki-rev-slide-vl-opt2-opt4 {
  padding: 10px 15px;
  color: black;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  background-color: white; }

.roki-rev-slide-vl-opt4-opt1 {
  padding: 10px 15px;
  color: #ed7721;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-vl-opt4-opt2 {
  padding: 10px 15px;
  color: white;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-vl-no-opt1 {
  padding: 10px 15px;
  color: #ed7721;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-vl-no-opt2 {
  padding: 10px 15px;
  color: white;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-vl-no-opt4 {
  padding: 8px 13px 9px;
  color: black;
  text-transform: uppercase;
  line-height: 45px;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-l-opt1-opt2 {
  padding: 10px;
  color: white;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-l-opt1-opt4 {
  padding: 10px;
  color: black;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-l-opt2-opt1 {
  padding: 10px;
  color: #ed7721;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  background-color: white; }

.roki-rev-slide-l-opt2-opt4 {
  padding: 10px;
  color: black;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  background-color: white; }

.roki-rev-slide-l-opt4-opt1 {
  padding: 10px;
  color: #ed7721;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-l-opt4-opt2 {
  padding: 10px;
  color: white;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-l-no-opt1 {
  padding: 10px;
  color: #ed7721;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-l-no-opt2 {
  padding: 10px;
  color: white;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-l-no-opt4 {
  padding: 10px;
  color: black;
  text-transform: uppercase;
  line-height: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-opt1-opt2 {
  padding: 8px 16px;
  color: white;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-opt1-opt4 {
  padding: 8px 16px;
  color: black;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-opt2-opt1 {
  padding: 8px 16px;
  color: #ed7721;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: white;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-opt2-opt4 {
  padding: 8px 16px;
  color: black;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: white;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-opt4-opt1 {
  padding: 8px 16px;
  color: #ed7721;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-opt4-opt2 {
  padding: 8px 16px;
  color: white;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-no-opt1 {
  padding: 8px 16px;
  color: #ed7721;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-no-opt2 {
  padding: 8px 16px;
  color: white;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-n-no-opt4 {
  padding: 8px 16px;
  color: black;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.roki-rev-slide-extra-text {
  width: 380px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 300;
  display: inline-block;
  white-space: normal !important; }

.roki-rev-slide-extra-text-bullets {
  width: 380px;
  line-height: 24px;
  padding-left: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  white-space: normal !important;
  position: relative; }

.roki-rev-slide-extra-text-bullets::after {
  border-radius: 100%;
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  color: white;
  display: block;
  position: absolute;
  content: " ";
  background-color: #ed7721;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%; }

div.features-preview {
  border-radius: 6px !important;
  width: 619px;
  height: 176px;
  margin-bottom: 60px;
  position: relative;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.26), inset 0 0 0 1px rgba(0, 0, 0, 0.12), inset 0 2px 6px 0 rgba(0, 0, 0, 0.26), 0 0 0 4px rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.06) !important;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.26), inset 0 0 0 1px rgba(0, 0, 0, 0.12), inset 0 2px 6px 0 rgba(0, 0, 0, 0.26), 0 0 0 4px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.26), inset 0 0 0 1px rgba(0, 0, 0, 0.12), inset 0 2px 6px 0 rgba(0, 0, 0, 0.26), 0 0 0 4px rgba(0, 0, 0, 0.03); }

div.features-preview > span {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.06) !important; }

div.features-preview img {
  top: -60px;
  position: absolute; }

div.builder.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/02/features-bg.jpg"); }

div.fontawesome.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/02/fontawesome-bg.jpg"); }

div.colors.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/02/colors-bg.jpg"); }

div.shortcodes.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/02/shortcodes-bg.jpg"); }

div.fonts.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/02/fonts-bg.jpg"); }

div.sidebars.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/02/sidebars-bg.jpg"); }

div.skin.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/02/skin-bg.jpg"); }

div.revolution.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/revolution-bg.jpg"); }

div.responsive.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/responsive-bg.jpg"); }

div.wide.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/wide-bg.jpg"); }

div.boxed.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/boxed-bg.jpg"); }

div.fluid.features-preview {
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/fluid-bg.jpg"); }

.iphone5-frame {
  background-position: left top;
  padding: 278px 108px 279px 106px;
  width: 640px;
  height: 1136px;
  position: relative;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/iphone5-frame.png");
  background-repeat: no-repeat; }

.iphone5-frame div.iphone-glare {
  background-position: right top;
  top: 69px;
  width: 415px;
  height: 1173px;
  right: 68px;
  position: absolute;
  z-index: 20;
  cursor: pointer;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/iphone-glare.png");
  background-repeat: no-repeat; }

.iphone5-frame div.iphone-wrap {
  left: 0;
  top: 0;
  width: 640px;
  height: 1136px;
  bottom: 0;
  overflow: hidden;
  position: relative; }

.iphone5-frame div.browser-bottom {
  background-position: left top;
  left: 0;
  width: 640px;
  height: 88px;
  right: 0;
  bottom: 0;
  position: absolute;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/bottom-browser.png");
  background-repeat: no-repeat; }

.iphone5-frame div.top-details {
  background-position: left top;
  left: 0;
  top: 0;
  width: 640px;
  height: 40px;
  right: 0;
  position: absolute;
  z-index: 11;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/iphone-top-details.png");
  background-repeat: no-repeat; }

.iphone5-frame div.iphone-content {
  background-position: -500px 0;
  left: 0;
  top: 40px;
  width: 640px;
  height: 1008px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/iphone-content.png");
  background-repeat: no-repeat; }

.iphone5-frame div.iphone-content:hover {
  background-position: 0 0; }

.percent-30.iphone5-frame {
  padding: 181px 104px 178px;
  width: 192px;
  height: 341px;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/iphone5-frame-30-percent.png"); }

.percent-30.iphone5-frame div.iphone-glare {
  top: 117px;
  width: 124px;
  height: 352px;
  right: 92px;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/iphone5-glare-30-percent.png"); }

.percent-30.iphone5-frame div.iphone-wrap {
  width: 192px;
  height: 341px; }

.percent-30.iphone5-frame div.top-details {
  width: 192px;
  height: 12px;
  position: absolute;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/iphone-top-details-30-percent.png"); }

.percent-30.iphone5-frame div.browser-bottom {
  width: 192px;
  height: 26px;
  background-image: url("http://antonibotev.com/themes/roki/wp-content/uploads/2013/01/bottom-browser-30-percent.png"); }

.percent-30.iphone5-frame div.iphone-content {
  background-position: -150px 0;
  top: 12px;
  width: 342px;
  height: 303px;
  background-image: url("http://antonibotev.com/themes/nova/wp-content/uploads/2013/02/nova-iphone-content-30-percent-300x265.jpg"); }

.iphone5-frame .help-text {
  display: none; }

.bluebox-heading-wrap {
  margin-bottom: 20px;
  display: block;
  position: relative;
  min-height: 20px;
  background-color: rgba(0, 0, 0, 0.02); }

h3.bluebox-heading {
  margin: 0;
  color: black;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300; }

h3.bluebox-heading {
  margin: 0;
  color: black;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300; }

.bluebox-heading-wrap h3.bluebox-heading {
  left: 0;
  top: 0;
  bottom: 0;
  line-height: 20px;
  padding-right: 20px;
  position: absolute;
  box-shadow: 4px 0 0 0 rgba(0, 0, 0, 0.04);
  background-color: white;
  -webkit-box-shadow: 4px 0 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 4px 0 0 0 rgba(0, 0, 0, 0.04); }

.bluebox-heading-wrap .prev-next-heading-links {
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute; }

.bluebox-heading-wrap .prev-next-heading-links a {
  width: 20px;
  height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
  line-height: 21px;
  font-size: 10px;
  margin-left: 1px;
  float: right;
  display: block;
  background-color: rgba(0, 0, 0, 0.02); }

.bluebox-heading-wrap .prev-next-heading-links a:hover {
  color: white;
  background-color: #ed7721; }

ul.bluebox-pagination {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.blog ul.bluebox-pagination {
  margin: 45px 0;
  width: 100%;
  float: left;
  display: block; }

.blog-layout-element ul.bluebox-pagination {
  width: 100%;
  margin-top: 30px;
  float: left;
  display: block; }

.portfolio-layout-element ul.bluebox-pagination {
  width: 100%;
  margin-top: 30px;
  float: left;
  display: block; }

ul.bluebox-pagination li {
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin-right: 1px;
  float: left;
  display: block; }

ul.bluebox-pagination li a {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

ul.bluebox-pagination li.current a {
  color: white;
  background-color: #ed7721; }

.bluebox-builder-row {
  margin-bottom: 45px;
  position: relative; }

:first-child.bluebox-builder-row {
  margin-top: 45px; }

.blog-layout-element > .row > .row > .blog-4-cols.row {
  margin-bottom: 45px; }

.blog-layout-element > .row > .row > :last-child.blog-4-cols.row {
  margin-bottom: 0; }

.image-details {
  display: block;
  position: relative; }

.image-details img {
  width: 100%;
  display: block; }

.image-details::after {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-image: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: ""; }

#main-content .iosSliderContainer {
  position: relative; }

#main-content .iosSliderContainer::before {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

#main-content .iosSliderContainer::after {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

#main-content .iosSliderContainer .image-wrap::before {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

#main-content .iosSliderContainer .image-wrap::after {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

#main-content .iosSliderContainer::before {
  left: 0;
  top: 0;
  height: 1px;
  right: 0; }

#main-content .iosSliderContainer::after {
  left: 0;
  height: 1px;
  right: 0;
  bottom: 0; }

#main-content .iosSliderContainer .image-wrap::before {
  left: 0;
  top: 0;
  width: 1px;
  bottom: 0; }

#main-content .iosSliderContainer .image-wrap::after {
  top: 0;
  width: 1px;
  right: 0;
  bottom: 0; }

.head-link {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: block; }

.head-link h4 {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: block; }

.image-wrap {
  position: relative; }

.image-wrap .image-details-link {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  z-index: 2; }

.bluebox-single-staff-wrap .image-details-link {
  right: 2px; }

.image-wrap .image-wrap-mask.image-details-link {
  transition: background-color 0.2s linear;
  background-color: transparent;
  -webkit-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
  -o-transition: background-color .2s linear; }

.image-wrap:hover .image-wrap-mask.image-details-link {
  background-color: rgba(237, 119, 33, 0.7); }

.bluebox-single-staff-wrap:hover .image-wrap-mask.image-details-link {
  background-color: rgba(237, 119, 33, 0.7); }

.image-wrap .icon-search {
  left: 50%;
  top: 50%;
  color: white;
  font-size: 24px;
  margin-top: -22px;
  margin-left: -10px;
  position: absolute;
  z-index: 3;
  opacity: 0; }

.image-wrap:hover .icon-search {
  margin-top: -12px;
  opacity: 1; }

.image-wrap .icon-search {
  transition: opacity 0.2s linear, margin-top 0.2s linear;
  -webkit-transition: opacity .2s linear, margin-top .2s linear;
  -moz-transition: opacity .2s linear, margin-top .2s linear;
  -o-transition: opacity .2s linear, margin-top .2s linear; }

.blog-4-cols .image-wrap {
  margin-bottom: 16px; }

.blog-3-cols .image-wrap {
  position: relative; }

.blog-4-cols .image-wrap::before {
  left: 50%;
  width: 0;
  height: 0;
  bottom: 0;
  position: absolute;
  z-index: 30;
  content: ""; }

.blog-4-cols .image-wrap::after {
  left: 50%;
  width: 0;
  height: 0;
  bottom: 0;
  position: absolute;
  z-index: 30;
  content: ""; }

.blog-4-cols .image-wrap::before {
  margin-left: -5px;
  border-right-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-left-color: transparent;
  border-right-width: 8px;
  border-bottom-width: 8px;
  border-left-width: 8px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  content: ""; }

.blog-4-cols .image-wrap::after {
  margin-left: -3px;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  content: ""; }

.blog-3-cols .image-wrap::before {
  top: 50%;
  width: 0;
  height: 0;
  right: -1px;
  position: absolute;
  z-index: 30;
  content: ""; }

.blog-3-cols .image-wrap::after {
  top: 50%;
  width: 0;
  height: 0;
  right: -1px;
  position: absolute;
  z-index: 30;
  content: ""; }

.blog-3-cols .image-wrap::before {
  margin-top: -5px;
  border-top-color: transparent;
  border-right-color: rgba(0, 0, 0, 0.06);
  border-bottom-color: transparent;
  border-top-width: 8px;
  border-right-width: 8px;
  border-bottom-width: 8px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  content: ""; }

.blog-3-cols .image-wrap::after {
  margin-top: -3px;
  border-top-color: transparent;
  border-right-color: white;
  border-bottom-color: transparent;
  border-top-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  content: ""; }

.blog-4-cols .head-link {
  text-transform: none;
  font-size: 14px;
  font-weight: 700; }

.blog-4-cols .head-link h4 {
  text-transform: none;
  font-size: 14px;
  font-weight: 700; }

.blog-1-col .head-link {
  text-transform: none;
  font-size: 13px;
  font-weight: 400;
  margin-left: 33%; }

.blog-1-col .head-link {
  text-transform: none;
  font-size: 13px;
  font-weight: 400; }

.blog-1-col .head-link h4 {
  text-transform: none;
  font-size: 13px;
  font-weight: 400; }

.bluebox-date {
  font-size: 11px;
  font-style: italic;
  font-weight: 300; }

.blog-1-col .bluebox-date {
  margin-left: 33%;
  display: block; }

.blog-4-cols p {
  margin-top: 10px;
  opacity: 0.5; }

.blog-3-cols p {
  margin-top: 10px;
  opacity: 0.5; }

.bluebox-more-link {
  display: inline-block; }

.bluebox-more-link span {
  font-size: 10px;
  margin-left: 6px;
  opacity: 0.16; }

.bluebox-more-link:hover span {
  opacity: 1; }

.blog-3-cols > .row {
  margin-bottom: 30px; }

.blog-3-cols > :last-child.row {
  margin-bottom: 0; }

.blog-1-col .list-item {
  margin-bottom: 20px; }

.blog-1-col .list-item .image-wrap {
  width: 28%;
  float: left; }

.blog-1-col .list-item .list-content {
  width: 72%;
  float: right; }

.search-results h3.line-heading {
  margin: 0;
  text-transform: uppercase;
  font-size: 18px; }

.search-results .status-publish {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom-color: rgba(0, 0, 0, 0.02);
  border-bottom-width: 20px;
  border-bottom-style: solid; }

.search-results :first-child.status-publish {
  margin-top: 45px; }

.search-results #main-content {
  margin-bottom: 45px; }

.has-filterable-categories.gumm-layout-element-grid.portfolio-layout-element .tagline {
  margin-top: -15px; }

.has-filterable-categories.gumm-layout-element-grid.staff-layout-element .tagline {
  margin-top: -15px; }

.portfolio-cols {
  margin-bottom: 30px; }

.project-line {
  margin-bottom: 60px; }

:last-child.portfolio-cols {
  margin-bottom: 0; }

:last-child.project-line {
  margin-bottom: 0; }

.portfolio-cols .head-link {
  font-size: 14px;
  font-weight: 700; }

.portfolio-cols .head-link h4 {
  font-size: 14px;
  font-weight: 700; }

.project-post-details {
  padding: 15px 20px 19px;
  position: relative;
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: #f9f9f9;
  -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.project-post-details::before {
  left: 20px;
  top: -6px;
  width: 0;
  height: 0;
  position: absolute;
  z-index: 5;
  content: ""; }

.project-post-details::after {
  left: 20px;
  top: -6px;
  width: 0;
  height: 0;
  position: absolute;
  z-index: 5;
  content: ""; }

.project-post-details::before {
  top: -8px;
  margin-left: -5px;
  border-right-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-left-color: transparent;
  border-right-width: 8px;
  border-bottom-width: 8px;
  border-left-width: 8px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  z-index: 4;
  content: ""; }

.project-post-details::after {
  margin-left: -3px;
  border-right-color: transparent;
  border-bottom-color: #f9f9f9;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  content: ""; }

.portfolio-cols .project-post-details p {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0; }

.tagline {
  margin: 30px 0 0;
  padding: 0;
  width: 100%;
  float: left; }

.has-filterable-categories.has-title .tagline {
  margin-top: 10px;
  margin-bottom: 30px; }

.has-filterable-categories .tagline {
  margin-top: 0;
  margin-bottom: 30px; }

.tagline ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  list-style-type: none; }

.tagline .filter-buttons.categories-list li {
  float: left;
  display: inline-block; }

.tagline a {
  margin: 0 1px 1px 0;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  font-size: 13px !important;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.load-more-link {
  margin: 0 1px 1px 0;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  font-size: 13px !important;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.tagline a:hover {
  color: white;
  background-color: #ed7721; }

.load-more-link:hover {
  color: white;
  background-color: #ed7721; }

.tagline a.current {
  color: white;
  background-color: #ed7721; }

.tagline li.current a {
  color: white;
  background-color: #ed7721; }

.bluebox-accordion .accordion-heading {
  line-height: 45px;
  padding-left: 55px;
  position: relative;
  cursor: pointer;
  min-height: 45px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-accordion :first-child.accordion-heading {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-accordion .accordion-heading a.accordion-button {
  left: 0;
  top: 0;
  width: 45px;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  line-height: 46px;
  position: absolute;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-accordion .accordion-heading a.accordion-button:hover {
  color: #ed7721;
  background-color: rgba(0, 0, 0, 0.04); }

.bluebox-accordion .accordion-heading:hover {
  color: #ed7721; }

.bluebox-accordion .ui-state-active.accordion-heading {
  color: white;
  background-color: #ed7721; }

.bluebox-accordion .ui-state-active.accordion-heading a.accordion-button {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.04); }

.bluebox-accordion .ui-state-active.accordion-heading a.accordion-button:hover {
  color: white; }

.bluebox-accordion .accordion-content {
  padding: 14px 20px 16px;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.progress {
  border-radius: 0;
  height: 45px;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

:last-child.progress {
  margin-bottom: 0; }

.progress .bar {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  text-shadow: none;
  background-image: none;
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.progress .bar > p {
  padding: 10px 0 11px;
  left: 0;
  top: 0;
  text-align: left;
  right: 0;
  bottom: 0;
  color: white;
  text-transform: uppercase;
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
  margin-left: 20px;
  float: left;
  position: absolute;
  text-shadow: none; }

.progress .bar > p span {
  font-weight: 300; }

.bluebox-fancy-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.bluebox-fancy-list li {
  padding-left: 30px;
  font-size: 14px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative; }

.bluebox-fancy-list li span {
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  color: white;
  line-height: 21px;
  font-size: 10px;
  display: block;
  position: absolute;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.nav-tabs > li > a {
  border-radius: 0;
  -webkit-border-radius: 0;
  -m-border-radius: 0; }

.bluebox-tabs .tab-content p:last-child {
  padding-bottom: 0;
  margin-bottom: 0; }

.bluebox-tabs .nav {
  padding-bottom: 0;
  margin-bottom: 0; }

.bluebox-tabs .tab-content {
  padding: 16px 20px 18px;
  border: 1px solid #e9e9e9;
  border-image: none;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  background-color: #f9f9f9;
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04); }

.bluebox-tabs .nav-tabs {
  border: 0 currentColor !important;
  border-image: none !important; }

.nav-tabs > .active > a {
  border-width: 1px;
  border-style: solid;
  border-color: #e9e9e9 #e9e9e9 #f9f9f9;
  border-image: none;
  color: #ed7721;
  margin-right: 0;
  box-shadow: none;
  background-color: #f9f9f9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-tabs > .active > a:hover {
  border-width: 1px;
  border-style: solid;
  border-color: #e9e9e9 #e9e9e9 #f9f9f9;
  border-image: none;
  color: #ed7721;
  margin-right: 0;
  box-shadow: none;
  background-color: #f9f9f9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.nav-tabs > .active > a > span {
  color: #ed7721; }

.nav-tabs > .active > a:hover > span {
  color: #ed7721; }

.nav-tabs > li > a {
  border-image: none;
  padding-right: 20px;
  padding-left: 36px;
  margin-right: 0;
  border-top-color: #e9e9e9;
  border-right-color: #e9e9e9;
  border-bottom-color: #e9e9e9;
  border-left-color: currentColor !important;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: medium !important;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: none !important;
  position: relative;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04); }

.nav-tabs > li > a:hover {
  border-image: none;
  padding-right: 20px;
  padding-left: 36px;
  margin-right: 0;
  border-top-color: #e9e9e9;
  border-right-color: #e9e9e9;
  border-bottom-color: #e9e9e9;
  border-left-color: currentColor !important;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: medium !important;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: none !important;
  position: relative;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04); }

.nav-tabs > li > a:hover {
  background-color: rgba(0, 0, 0, 0.02); }

.nav-tabs > li > a > span {
  left: 20px;
  top: 50%;
  color: rgba(0, 0, 0, 0.26);
  font-size: 10px;
  margin-top: -4px;
  position: absolute; }

.nav-tabs > li > a:hover > span {
  color: #ed7721; }

.nav-tabs > li:first-child > a {
  border-left-color: #e9e9e9 !important;
  border-left-width: 1px !important;
  border-left-style: solid !important; }

.nav-tabs > li:first-child > a:hover {
  border-left-color: #e9e9e9 !important;
  border-left-width: 1px !important;
  border-left-style: solid !important; }

.prev-next-links {
  left: 0;
  width: 91px;
  height: 45px;
  bottom: 0;
  position: absolute; }

.image-wrap:hover .prev-next-links {
  display: block; }

.prev-next-links a {
  width: 45px;
  height: 45px;
  text-align: center;
  color: white;
  line-height: 46px;
  font-size: 16px;
  float: left;
  display: block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.prev-next-links a:hover {
  color: white; }

.prev-next-links a.prev {
  float: left; }

.prev-next-links a.next {
  float: right; }

.prev-next-links a:hover {
  background-color: rgba(0, 0, 0, 0.74); }

.slide-pagination {
  margin: 0;
  padding: 0;
  height: 20px;
  list-style-type: none; }

.gallery-slide .slide-pagination {
  right: 0;
  bottom: 0;
  position: absolute; }

.image-wrap:hover .slide-pagination {
  display: block; }

.slide-pagination li {
  width: 20px;
  height: 20px;
  margin-left: 1px;
  float: left; }

.slide-pagination li a {
  width: 20px;
  height: 20px;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.slide-pagination li a:hover {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.74); }

.slide-pagination li.current a {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.74); }

.slide-pagination li.current a:hover {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.74); }

.bluebox-partners {
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.bluebox-partners ul.partners-slide {
  margin: 0;
  padding: 0;
  display: block;
  list-style-type: none; }

.bluebox-partners ul.partners-slide li {
  width: 20%;
  float: left;
  display: inline-block;
  position: relative; }

.bluebox-partners ul.partners-slide li a {
  margin: 1px 1px 4px;
  text-align: center;
  display: block; }

.bluebox-partners ul.partners-slide li a:hover::before {
  left: 0;
  height: 4px;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
  background-color: #ed7721; }

.bluebox-partners ul.partners-slide li a:hover::after {
  left: 50%;
  bottom: 4px;
  margin-left: -3px;
  border-right-color: transparent;
  border-bottom-color: #ed7721;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

.bluebox-partners ul.partners-slide li a img {
  text-align: center;
  display: inline-block;
  opacity: 0.6; }

.bluebox-partners ul.partners-slide li a:hover img {
  opacity: 1; }

.bluebox-contact {
  position: relative; }

.bluebox-contact label {
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
  font-size: 13px;
  margin-top: -5px; }

.bluebox-contact input[type='text'] {
  padding: 12px 3% 11px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 94%;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 20px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06); }

.type-three.bluebox-contact input[type='text'] {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.type-four.bluebox-contact input[type='text'] {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-contact textarea {
  padding: 12px 3% 11px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 94%;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06); }

.type-three.bluebox-contact textarea {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.type-four.bluebox-contact textarea {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-contact input[type='submit'] {
  padding: 0 20px;
  border: currentColor;
  border-image: none;
  height: 45px;
  color: white;
  line-height: 45px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin-top: 20px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-contact input[type='submit']:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.74); }

.type-one.bluebox-contact input[type='submit'] {
  left: 0;
  bottom: 0;
  margin-top: 0;
  position: absolute; }

.type-two.bluebox-contact input[type='submit'] {
  left: 0;
  bottom: 0;
  margin-top: 0;
  position: absolute; }

.type-one.bluebox-contact {
  padding: 20px 20px 65px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.type-two.bluebox-contact {
  padding: 20px 20px 65px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.type-one.bluebox-contact form {
  margin-bottom: 0; }

.type-two.bluebox-contact form {
  margin-bottom: 0; }

.google-map-layout-element {
  margin-top: -1px;
  position: relative; }

.google-map-layout-element::before {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

.google-map-layout-element::after {
  position: absolute;
  z-index: 160;
  content: "";
  background-color: rgba(0, 0, 0, 0.06); }

.google-map-layout-element::before {
  left: 0;
  top: 0;
  height: 1px;
  right: 0; }

.google-map-layout-element::after {
  left: 0;
  height: 1px;
  right: 0;
  bottom: 0; }

ul.cols-2.bluebox-pricing-table > li {
  width: 50%; }

ul.cols-3.bluebox-pricing-table > li {
  width: 33.33%; }

ul.cols-4.bluebox-pricing-table > li {
  width: 25%; }

ul.cols-5.bluebox-pricing-table > li {
  width: 20%; }

ul.cols-6.bluebox-pricing-table > li {
  width: 16.66%; }

ul.bluebox-pricing-table {
  margin: 0;
  padding: 0;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  float: left;
  display: block;
  list-style-type: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

ul.bluebox-pricing-table strong {
  color: black; }

ul.bluebox-pricing-table > li {
  height: 100%;
  text-align: center;
  float: left;
  position: relative; }

ul.bluebox-pricing-table li > div {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: relative;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

ul.bluebox-pricing-table li.selected > div {
  margin: -10px;
  position: relative;
  z-index: 100;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.03), 0 0 0 1px rgba(0, 0, 0, 0.06) !important;
  background-color: white;
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.03), 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.03), 0 0 0 1px rgba(0, 0, 0, 0.06); }

ul.bluebox-pricing-table li.selected > div li.price-row div {
  box-shadow: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

ul.bluebox-pricing-table > li:last-child > div {
  box-shadow: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

ul.bluebox-pricing-table li > div ul {
  margin: 0;
  padding: 0;
  display: block;
  list-style-type: none;
  position: relative; }

ul.bluebox-pricing-table li > div ul li {
  list-style: none;
  margin: 0;
  padding: 11px 30px 14px;
  display: block;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

ul.bluebox-pricing-table li > div ul li:last-child {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

ul.bluebox-pricing-table li > div ul li.heading-row {
  padding: 3px 30px; }

ul.bluebox-pricing-table li > div ul li.heading-row h3 {
  margin: 0;
  display: block; }

ul.bluebox-pricing-table li > div ul li.heading-row h3 {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  display: block; }

ul.bluebox-pricing-table li > div ul li.price-row {
  padding: 0;
  display: block; }

ul.bluebox-pricing-table li > div ul li.price-row div {
  background: white;
  padding: 18px 25px 17px;
  border-bottom-color: #ed7721;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  position: relative; }

ul.bluebox-pricing-table li > div ul li.price-row div::after {
  left: 50%;
  width: 0;
  height: 0;
  bottom: 0;
  margin-left: -3px;
  border-right-color: transparent;
  border-bottom-color: #ed7721;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

ul.bluebox-pricing-table li.selected > div ul li.price-row div {
  padding: 28px 25px 27px; }

ul.bluebox-pricing-table li:first-child > div ul li.price-row div {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06); }

ul.bluebox-pricing-table li > div ul li.price-row div strong {
  margin: 0 0 0 2px;
  font-size: 28px;
  font-weight: 700; }

ul.bluebox-pricing-table li > div ul li.price-row div span {
  width: 100%;
  line-height: 18px;
  display: inline-block; }

ul.bluebox-pricing-table li a.bluebox-button {
  margin: 9px 0 11px; }

.page-404 {
  margin: 100px auto;
  text-align: center; }

.page-404 .bluebox-icon-container {
  margin-right: 0;
  float: none;
  line-height: 4em; }

.page-404 .head-link {
  margin-top: 25px;
  margin-bottom: 17px; }

.staff-layout-element .row .row {
  margin-top: 30px; }

.staff-layout-element .row :first-child.row {
  margin-top: 0; }

.bluebox-staff .content-details {
  padding: 15px 20px 19px;
  position: relative;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.bluebox-staff .content-details span.position {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 2px;
  display: block; }

.bluebox-staff .content-details p {
  margin-top: 10px; }

.bluebox-staff .content-details .social-links {
  margin: 0;
  padding: 0;
  top: 28px;
  height: 20px;
  right: 10px;
  display: block;
  list-style-type: none;
  position: absolute; }

.bluebox-staff .content-details .social-links li {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  float: left;
  display: block; }

.bluebox-staff .content-details .social-links li a {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  float: left;
  display: block; }

.bluebox-staff.span2 .content-details .social-links li {
  display: none; }

.bluebox-staff .content-details::before {
  left: 0;
  top: -4px;
  height: 4px;
  right: 0;
  position: absolute;
  content: "";
  background-color: #ed7721; }

.bluebox-staff .content-details::after {
  left: 20px;
  top: -10px;
  width: 0;
  height: 0;
  border-right-color: transparent;
  border-bottom-color: #ed7721;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

.bluebox-staff .content-details p:last-child {
  margin-bottom: 0; }

.bluebox-icon-container {
  width: 45px;
  height: 45px;
  color: white;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.74); }

.bluebox-icon-container {
  width: 45px;
  height: 45px;
  text-align: center;
  color: white;
  line-height: 45px;
  margin-right: 18px;
  float: left;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.74); }

.bluebox-icon-container span {
  width: 45px;
  height: 45px;
  color: white;
  line-height: 46px;
  font-size: 24px;
  display: inline-block; }

.bluebox-icon-container:hover {
  background-color: #ed7721; }

.text-box-style-one .head-link {
  margin-top: 13px; }

.bluebox-textboxes p {
  color: rgba(0, 0, 0, 0.5); }

.text-box-style-one p {
  width: 100%;
  clear: both;
  margin-top: 14px; }

.text-box-style-two .head-link {
  padding-right: 30px;
  margin-bottom: 13px;
  float: left;
  display: inline-block;
  position: relative; }

.text-box-style-two .head-link span {
  top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  right: 0;
  color: rgba(0, 0, 0, 0.26);
  line-height: 21px;
  font-size: 10px;
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.02); }

.text-box-style-two .head-link:hover span {
  color: white;
  background-color: #ed7721; }

.text-box-container {
  padding: 20px 20px 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.text-box-style-three .head-link {
  margin-top: 5px;
  margin-bottom: 17px;
  display: block; }

.text-box-style-three .bluebox-button {
  margin-top: 6px;
  margin-left: -20px; }

.text-box-style-four .head-link {
  margin-top: 15px;
  margin-bottom: 7px;
  display: block; }

.bb-gauge-icon-container {
  display: inline-block; }

.bb-gauge-icon-container span {
  display: inline-block; }

.text-box-gauge .canvas-gauge-element {
  margin-bottom: 15px; }

.text-box-gauge h4.head-link {
  margin-bottom: 7px; }

.text-box-gauge p:last-child {
  margin-bottom: 0; }

.text-box-style-one.text-box-gauge p {
  margin: 0 !important; }

.text-box-gauge p {
  margin: 0 !important; }

.text-box-gauge .bluebox-more-link {
  margin-top: 10px; }

.bluebox-fancy-text {
  padding: 26px 30px 30px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.bluebox-fancy-text .fancy-content {
  display: inline-block;
  position: relative;
  z-index: 30; }

.bluebox-fancy-text .head-link {
  font-size: 18px; }

.bluebox-fancy-text .fancy-content p {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0; }

.bluebox-fancy-text .fancy-content :nth-child(2) {
  margin-top: 10px; }

.bluebox-fancy-text {
  padding: 26px 30px 30px;
  position: relative;
  z-index: 30;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.fancy-colorful.bluebox-fancy-text {
  background-color: #ed7721; }

.fancy-colorful.full-width.bluebox-fancy-text .element-background {
  background-color: #ed7721; }

.fancy-colorful.bluebox-fancy-text h4 {
  color: white; }

.fancy-colorful.bluebox-fancy-text p {
  color: rgba(255, 255, 255, 0.5); }

.fancy-colorful.bluebox-fancy-text .bluebox-button {
  background-repeat: repeat;
  background-color: rgba(0, 0, 0, 0.26); }

.bluebox-fancy-text .button-container {
  margin-right: -30px;
  float: right;
  position: relative;
  z-index: 30; }

.bluebox-center.bluebox-fancy-text {
  text-align: center; }

.bluebox-center.bluebox-fancy-text .fancy-content {
  float: none;
  display: block; }

.bluebox-center.bluebox-fancy-text .button-container {
  float: none;
  display: block; }

.bluebox-center.bluebox-fancy-text .button-container {
  margin-top: 24px !important;
  float: none;
  display: block; }

.full-width.bluebox-fancy-text {
  padding: 26px 30px 27px 0 !important;
  position: static;
  box-shadow: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.full-width.bluebox-fancy-text .element-background {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 20;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-quotes {
  padding: 29px 30px 30px;
  text-align: center;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.quote-colorful.bluebox-quotes {
  background-color: #ed7721; }

.full-width.quote-colorful.bluebox-quotes {
  background-color: #ed7721; }

.span12 .quote-colorful.full-width.bluebox-quotes .element-background {
  background-color: #ed7721; }

.quote-colorful.bluebox-quotes em {
  color: white; }

.quote-colorful.bluebox-quotes .quotes-arrows li a {
  color: white; }

.quote-colorful.bluebox-quotes p {
  color: rgba(255, 255, 255, 0.5); }

.bluebox-quotes .quote-content {
  padding: 0 75px;
  position: relative;
  z-index: 30; }

.span6 > .bluebox-quotes .quote-content {
  padding: 0 45px; }

.quote-fancy.bluebox-quotes .quote-content {
  padding: 0; }

.bluebox-quotes em {
  font-size: 18px; }

.quote-fancy.bluebox-quotes {
  padding: 22px 0 0;
  background-color: transparent; }

.quote-colorful.quote-fancy.bluebox-quotes {
  background-color: #ed7721; }

.quote-fancy.bluebox-quotes em {
  padding: 0 20px;
  font-size: 14px;
  display: inline-block; }

.bluebox-quotes p {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
  margin-bottom: 0; }

.quote-fancy.bluebox-quotes p {
  padding: 14px 30px 20px;
  margin-top: 22px !important;
  margin-right: -30px;
  margin-left: -30px;
  border-top-color: #ed7721;
  border-top-width: 4px;
  border-top-style: solid;
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.02); }

.quote-fancy.bluebox-quotes p.no-author {
  padding: 0 30px; }

.quote-colorful.quote-fancy.bluebox-quotes p {
  border-top-color: white;
  border-top-width: 4px;
  border-top-style: solid;
  background-color: rgba(0, 0, 0, 0.06); }

.quote-fancy.bluebox-quotes p::after {
  left: 50%;
  top: -10px;
  width: 0;
  height: 0;
  margin-left: -6px;
  border-right-color: transparent;
  border-bottom-color: #ed7721;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

.quote-colorful.quote-fancy.bluebox-quotes p::after {
  border-bottom-color: white;
  border-bottom-width: 6px;
  border-bottom-style: solid; }

.quote-colorful.quote-fancy.bluebox-quotes p strong {
  color: white;
  display: block; }

.quote-fancy.bluebox-quotes p strong {
  color: black;
  display: block; }

.quotes-arrows {
  margin: -23px 0 0 !important;
  padding: 0;
  left: -30px;
  top: 50%;
  height: 0;
  right: -30px;
  list-style-type: none;
  position: absolute; }

.quote-fancy.bluebox-quotes .quotes-arrows {
  display: none; }

.quotes-arrows li {
  margin: 0 !important;
  width: 45px;
  height: 45px;
  display: block; }

.quotes-arrows li a {
  width: 45px;
  height: 45px;
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
  line-height: 46px;
  font-size: 16px;
  display: block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.quotes-arrows li.prev {
  float: left; }

.quotes-arrows li.next {
  float: right; }

.full-width.bluebox-quotes {
  padding: 29px 30px 27px;
  position: static;
  box-shadow: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.full-width.bluebox-quotes .element-background {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 20;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-twitter-element {
  color: white;
  position: relative;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06);
  background-color: #34c6e5;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06); }

body .bluebox-twitter-element a {
  color: white; }

body .bluebox-twitter-element a:hover {
  color: white;
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: dotted; }

.bluebox-twitter-element::after {
  left: 50%;
  width: 0;
  height: 0;
  bottom: 0;
  margin-left: -6px;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 21;
  content: ""; }

.bluebox-twitter-element .twitter-content {
  padding: 12px 0 13px;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  white-space: nowrap;
  position: relative;
  z-index: 30;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06);
  background-color: #4099ff;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-twitter-element .prev-next-links {
  left: inherit !important;
  top: 0;
  width: 91px;
  height: 45px;
  right: 0 !important;
  bottom: 0;
  display: block !important;
  position: absolute;
  z-index: 31; }

.bluebox-twitter-element .prev-next-links a {
  background-color: rgba(0, 0, 0, 0.06); }

.bluebox-twitter-element .prev-next-links a:hover {
  border-bottom-color: currentColor;
  border-bottom-width: medium;
  border-bottom-style: none;
  background-color: rgba(0, 0, 0, 0.16); }

.twitter-dark-half {
  left: 0;
  top: 0;
  right: 50%;
  bottom: 0;
  position: absolute;
  z-index: 20;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06);
  background-color: #3c90f0;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-twitter-element .twitter-content strong.bluebox-twitter-author {
  padding: 12px 20px 13px 0;
  font-size: 14px;
  margin-top: -12px;
  margin-right: 15px;
  margin-bottom: -13px;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06);
  background-color: #3c90f0;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-twitter-element .twitter-content strong.bluebox-twitter-author::before {
  top: 50%;
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
  z-index: 30;
  content: ""; }

.bluebox-twitter-element .twitter-content strong.bluebox-twitter-author::after {
  top: 50%;
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
  z-index: 30;
  content: ""; }

.bluebox-twitter-element .twitter-content strong.bluebox-twitter-author::before {
  margin-top: -9px;
  border-top-color: transparent;
  border-right-color: rgba(0, 0, 0, 0.06);
  border-bottom-color: transparent;
  border-top-width: 8px;
  border-right-width: 8px;
  border-bottom-width: 8px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  content: ""; }

.bluebox-twitter-element .twitter-content strong.bluebox-twitter-author::after {
  margin-top: -7px;
  border-top-color: transparent;
  border-right-color: #4099ff;
  border-bottom-color: transparent;
  border-top-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  content: ""; }

.no-margin.bluebox-builder-row {
  margin-bottom: 0; }

.bluebox-twitter-element .tweets-container .inactive.gumm-tweet-item {
  display: none; }

.full-width.bluebox-twitter-element {
  position: static; }

.full-width.bluebox-twitter-element .twitter-light-half {
  left: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  position: absolute;
  z-index: 20;
  background-color: #4099ff; }

.full-width.bluebox-twitter-element .twitter-light-half {
  display: block; }

.progress-bars .progress .bar {
  transition: width 1s ease-in-out;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out; }

.fancy-text-layout-element .not-initialized.button-container {
  opacity: 0 !important; }

.bluebox-fancy-list li.not-initialized {
  opacity: 0 !important; }

.blog-1-col .not-initialized.list-item {
  opacity: 0 !important; }

.fancy-text-layout-element .button-container {
  transition: opacity 0.6s linear;
  -webkit-transition: opacity .6s linear;
  -moz-transition: opacity .6s linear;
  -o-transition: opacity .6s linear; }

.bluebox-fancy-list li {
  transition: opacity 0.6s linear;
  -webkit-transition: opacity .6s linear;
  -moz-transition: opacity .6s linear;
  -o-transition: opacity .6s linear; }

.blog-1-col .list-item {
  transition: opacity 0.6s linear;
  -webkit-transition: opacity .6s linear;
  -moz-transition: opacity .6s linear;
  -o-transition: opacity .6s linear; }

.not-initialized.progress-bars .progress .bar {
  width: 0 !important; }

.gumm-layout-element-slider .bluebox-partners {
  padding-bottom: 10px; }

.blog-loop-standard .blog-line {
  margin-top: 60px; }

.blog-loop-standard :first-child.blog-line {
  margin-top: 0; }

.blog-loop-standard .line-details {
  position: relative;
  z-index: 2; }

.blog-loop-standard .line-details div {
  width: 45px;
  height: 45px;
  text-align: center;
  color: white;
  position: absolute; }

.blog-loop-standard .line-details .line-date {
  left: 0;
  bottom: 0;
  line-height: 16px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.blog-loop-standard .line-details .line-post-format {
  left: 0;
  bottom: 45px;
  line-height: 46px;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.74); }

.blog-loop-standard .line-details .line-date strong {
  width: 100%;
  line-height: 20px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 6px;
  display: inline-block; }

.blog-loop-standard .line-details .line-date span {
  left: 0;
  top: 22px;
  width: 100%;
  right: 0;
  display: inline-block;
  position: absolute; }

.blog-loop-standard h3.line-heading {
  margin: 24px 0 15px;
  text-transform: uppercase;
  line-height: 24px;
  font-size: 18px; }

.line-meta-details {
  padding: 8px 10px 9px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  font-size: 11px;
  font-style: italic;
  margin-bottom: 15px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.line-meta-details {
  margin-top: 20px; }

.blog-loop-standard p {
  color: rgba(0, 0, 0, 0.5); }

.blog-loop-standard .blog-half {
  width: 50%;
  float: left; }

.blog-loop-standard .blog-half > .image-wrap {
  margin-right: 20px; }

.blog-loop-standard .blog-half > .half-content {
  margin-left: 0; }

.blog-loop-standard .blog-half > div h3.line-heading {
  margin-top: 0; }

.portfolio-loop .line-details div {
  width: 45px;
  height: 45px;
  text-align: center;
  color: white;
  position: absolute; }

.portfolio-loop h3.line-heading {
  margin: 24px 0 9px;
  text-transform: uppercase;
  line-height: 24px;
  font-size: 18px; }

.portfolio-loop em.tags {
  margin-bottom: 20px;
  display: block; }

.portfolio-loop em.tags a {
  color: rgba(0, 0, 0, 0.5); }

.portfolio-loop p {
  color: rgba(0, 0, 0, 0.5); }

.portfolio-loop .project-half {
  width: 50%;
  float: left; }

.portfolio-loop .project-half > .image-wrap {
  margin-right: 20px; }

.portfolio-loop .project-half > .half-content {
  margin-left: 0; }

.portfolio-loop .project-half > div h3.line-heading {
  margin-top: 0; }

.portfolio-loop .project-half > .half-content .light.bluebox-button {
  margin-left: 20px; }

.wide.project-wrap {
  margin-bottom: 30px; }

.project-wrap .span4 .project-description {
  margin-bottom: 30px; }

.project-wrap .span4 .project-description p:last-child {
  margin-bottom: 0; }

.project-wrap .span4 .project-details span.span-bb-label {
  margin: 0 10px 0 0 !important;
  width: auto;
  text-transform: uppercase;
  font-weight: 300;
  float: none;
  display: inline; }

.blog-post-single-wrap {
  margin-top: 30px; }

.blog-post-single-wrap .bluebox-builder-row {
  margin-bottom: 20px; }

.blog-post-single-wrap .bluebox-builder-row .single-featured-image-layout-element {
  margin-bottom: 10px; }

.blog-post-single-wrap .bluebox-builder-row .single-taxonomies-layout-element .tagline {
  margin: 0 0 10px; }

.blog-post-single-wrap .bluebox-builder-row .single-related-layout-element {
  margin-bottom: 10px; }

.blog-post-single-wrap .bluebox-builder-row .single-comments-layout-element {
  margin-bottom: 25px; }

.blog-post-single-wrap .gallery-slide {
  margin-bottom: 30px; }

.blog-post-single-wrap .line-meta-details {
  margin: 0; }

.blog-post-single-wrap .post-content {
  margin-top: 20px; }

.blog-post-single-wrap .post-content p:last-child {
  margin: 0; }

.blog-post-single-wrap .tagline {
  margin-bottom: 29px; }

.blog-post-single-wrap .bluebox-share-options {
  height: 45px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.blog-post-single-wrap .bluebox-share-options > span {
  text-transform: uppercase;
  line-height: 45px;
  font-weight: 300;
  margin-left: 20px;
  display: inline-block; }

.blog-post-single-wrap .bluebox-about-author-wrap {
  margin-top: 40px; }

.blog-post-single-wrap .bluebox-about-author {
  float: left;
  position: relative; }

.bluebox-about-author-wrap .row {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.blog-post-single-wrap .bluebox-about-author-wrap .bluebox-about-author-description {
  padding: 20px;
  display: block; }

.blog-post-single-wrap .author-image {
  width: 15%;
  margin-right: 20px;
  float: left; }

.blog-post-single-wrap .bluebox-related-blog-posts {
  margin-top: 40px; }

.blog-post-single-wrap .bluebox-related-blog-posts .slides .row {
  margin-bottom: 30px; }

.blog-post-single-wrap .bluebox-related-blog-posts > :last-child.row {
  margin-bottom: 0; }

.bluebox-blog-post-comments {
  margin-top: 60px; }

.bluebox-blog-post-reply {
  margin-top: 60px; }

#respond #reply-title {
  display: none; }

p.form-submit {
  margin: 0; }

ol.comment-list {
  margin: 20px 0 0;
  padding: 0;
  list-style-type: none; }

ul.children {
  margin: 20px 0 0;
  padding: 0;
  list-style-type: none; }

ul.children li:last-child {
  padding-bottom: 0 !important; }

ol.comment-list li.comment {
  padding: 0 0 20px 60px;
  margin-top: 30px;
  display: block;
  position: relative; }

ol.comment-list li:first-child.comment {
  margin-top: 0; }

ol.comment-list > li.comment {
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-bottom-style: solid; }

ol.comment-list > li:last-child.comment {
  border-bottom-color: currentColor;
  border-bottom-width: medium;
  border-bottom-style: none; }

ol.comment-list li.comment div.comment-author cite {
  margin: 0 10px 10px 0;
  font-size: 13px;
  font-weight: 700; }

ol.comment-list li.comment span.says {
  display: none; }

ol.comment-list li.comment div.comment-author cite a {
  color: black; }

ol.comment-list li.comment div.comment-author cite a:hover {
  color: #ed7721; }

ol.comment-list li.comment div.comment-author {
  float: left; }

ol.comment-list li.comment div.comment-meta {
  float: left; }

ol.comment-list li.comment div.comment-meta {
  font-size: 13px; }

ol.comment-list li.comment div.comment-meta a {
  color: rgba(0, 0, 0, 0.5); }

ol.comment-list li.comment div.comment-meta a:hover {
  color: #ed7721; }

ol.comment-list li.comment div.comment-body > p {
  padding-top: 6px;
  clear: both; }

ol.comment-list li.comment div.comment-author img {
  left: 0;
  top: 0;
  float: left;
  display: block;
  position: absolute;
  background-color: white; }

ol.comment-list > li:last-child.comment {
  margin-bottom: 15px; }

.widget-wrap ul.bluebox-widget-menu a {
  font-weight: 700; }

.widget-wrap ul.bluebox-widget-menu a span {
  color: rgba(0, 0, 0, 0.16);
  font-size: 10px;
  margin-right: 6px; }

.widget-wrap ul.bluebox-widget-menu a:hover span {
  color: #ed7721; }

.bluebox-footer-content .widget-wrap ul.bluebox-widget-menu a span {
  color: rgba(255, 255, 255, 0.16); }

.bluebox-footer-content .widget-wrap ul.bluebox-widget-menu a:hover span {
  color: #ed7721; }

form.search-form {
  margin-bottom: 0; }

.text-input-wrapper {
  position: relative; }

.text-input-wrapper i {
  left: 15px;
  top: 15px;
  position: absolute; }

.widget-wrap ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.widget-wrap ul li {
  margin-bottom: 13px; }

.widget-wrap ul li:last-child {
  margin-bottom: 0; }

.widget-wrap ul.nav-tabs li {
  margin-bottom: 0; }

.tagcloud a {
  margin: 0 1px 1px 0;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  font-size: 13px !important;
  float: left;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.tagcloud a:hover {
  color: white;
  background-color: #ed7721; }

.widget-wrap table#wp-calendar {
  width: 100%; }

.widget-wrap table#wp-calendar td {
  padding: 10px;
  text-align: center; }

.widget-wrap table#wp-calendar caption {
  padding: 10px;
  text-align: center; }

.bluebox-footer-content h3.bluebox-heading {
  margin: 0 0 15px;
  color: white;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700; }

.bluebox-footer-content .widget-wrap .heading-wrap h3 {
  margin: 0 0 15px;
  color: white;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700; }

.bluebox-footer-content {
  padding: 55px 0 45px;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.04);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.04);
  -moz-box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.04); }

.bluebox-footer-content::after {
  left: 50%;
  top: -6px;
  width: 0;
  height: 0;
  margin-left: -6px;
  border-right-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.74);
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 22;
  content: ""; }

.bluebox-copyrights-wrap {
  padding: 42px 0 33px;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  background-color: rgba(0, 0, 0, 0.8); }

.bluebox-footer-content a {
  color: rgba(255, 255, 255, 0.5); }

.bluebox-copyrights-wrap a {
  color: rgba(255, 255, 255, 0.5); }

.bluebox-footer-content .tagcloud a {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04); }

.bluebox-footer-content .tagcloud a:hover {
  color: white;
  background-color: #ed7721; }

.bluebox-authors-wrap .bluebox-authors-content {
  text-align: right;
  padding-right: 75px;
  position: relative; }

.bluebox-authors-wrap .bluebox-authors-content a.back-to-top {
  top: -12px;
  width: 45px;
  height: 45px;
  text-align: center;
  right: 0;
  color: rgba(255, 255, 255, 0.26);
  line-height: 46px;
  font-size: 16px;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04); }

.bluebox-footer-content .bluebox-details-social {
  float: left; }

.bluebox-footer-content a.social-link {
  margin: 0 1px 1px 0;
  width: 45px;
  height: 45px;
  text-align: center;
  color: rgba(255, 255, 255, 0.26);
  line-height: 46px;
  font-size: 16px;
  float: left;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04) !important;
  background-color: rgba(255, 255, 255, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04); }

.bluebox-footer-content a.social-link:hover {
  color: white;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04); }

.bluebox-authors-wrap .bluebox-authors-content a.back-to-top:hover {
  color: white;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  background-color: #ed7721;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04); }

.bluebox-footer-content .type-one.bluebox-contact {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.04); }

.bluebox-footer-content .bluebox-contact input[type='text'] {
  color: rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-footer-content .bluebox-contact textarea {
  color: rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-footer-content .widget-wrap form.search-form input.text-input {
  padding: 12px 8% 12px 16%;
  border-radius: 0;
  border: currentColor !important;
  border-image: none !important;
  width: 76%;
  height: 21px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.06);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.hover-light:hover {
  color: #ed7721 !important;
  background-color: white !important; }

.hover-light:hover span {
  color: #ed7721 !important; }

.hover-dark:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.bluebox-slider-content .slide-details .details-wrap a.bluebox-button:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.bluebox-fancy-text .bluebox-button:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.quote-colorful .quotes-arrows li a:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.text-box-style-three .bluebox-button:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.bluebox-pricing-table .bluebox-button:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.roki-button-link:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.portfolio-loop .project-half > .half-content .bluebox-button:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.hover-dark:hover span {
  color: white !important; }

.bluebox-slider-content .slide-details .details-wrap a.bluebox-button:hover span {
  color: white !important; }

.bluebox-fancy-text .bluebox-button:hover span {
  color: white !important; }

.quote-colorful .quotes-arrows li a:hover span {
  color: white !important; }

.text-box-style-three .bluebox-button:hover span {
  color: white !important; }

.bluebox-pricing-table .bluebox-button:hover span {
  color: white !important; }

.roki-button-link:hover span {
  color: white !important; }

.portfolio-loop .project-half > .half-content .bluebox-button:hover span {
  color: white !important; }

.hover-colorful:hover {
  color: white !important;
  background-color: #ed7721 !important; }

.quotes-arrows li a:hover {
  color: white !important;
  background-color: #ed7721 !important; }

.light.bluebox-button:hover {
  color: white !important;
  background-color: #ed7721 !important; }

.bluebox-heading-arrows a.bluebox-shadows:hover {
  color: white !important;
  background-color: #ed7721 !important; }

ul.bluebox-pagination li a:hover {
  color: white !important;
  background-color: #ed7721 !important; }

.hover-colorful:hover span {
  color: white !important; }

.quotes-arrows li a:hover span {
  color: white !important; }

.light.bluebox-button:hover span {
  color: white !important; }

body.admin-bar #mobile-menu {
  top: 28px; }

.dropdown-menu li > a:hover {
  background-image: none !important;
  background-color: transparent !important; }

#mobile-menu {
  left: 0;
  top: 0;
  width: 250px;
  bottom: 0;
  overflow: hidden;
  display: none;
  position: fixed;
  z-index: -1;
  -ms-overflow-y: scroll;
  background-color: #2a2a2a; }

#mobile-menu .prime-nav-mobile-list {
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  border-bottom-color: rgba(255, 255, 255, 0.09);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  list-style-type: none;
  position: relative; }

#mobile-menu .prime-nav-mobile-list li {
  border-top-color: rgba(255, 255, 255, 0.16);
  border-bottom-color: rgba(0, 0, 0, 0.6);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  position: relative; }

#mobile-menu .prime-nav-mobile-list li.current-menu-item > a {
  border-radius: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

#mobile-menu .prime-nav-mobile-list li.current-menu-item > a:hover {
  border-radius: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

#mobile-menu .prime-nav-mobile-list li.current-menu-parent > a {
  border-radius: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

#mobile-menu .prime-nav-mobile-list li.current-menu-parent > a:hover {
  border-radius: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

#mobile-menu .prime-nav-mobile-list .dropdown-menu li.current-menu-item > a {
  border-radius: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

#mobile-menu .prime-nav-mobile-list .dropdown-menu li.current-menu-item > a:hover {
  border-radius: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

#mobile-menu .prime-nav-mobile-list li.current-menu-item > a {
  background-color: rgba(0, 0, 0, 0.26) !important; }

#mobile-menu .prime-nav-mobile-list li > a:hover {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

#mobile-menu .prime-nav-mobile-list li > i {
  padding: 18px 10px 17px;
  left: 0;
  top: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.3);
  line-height: 25px;
  font-size: 18px;
  display: inline-block;
  position: absolute;
  z-index: 51;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(0, 0, 0, 0.6); }

#mobile-menu .prime-nav-mobile-list li a {
  padding: 17px 45px 18px 40px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 25px;
  display: block;
  min-height: 25px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6); }

#mobile-menu .prime-nav-mobile-list li:last-child a {
  border-bottom-color: currentColor;
  border-bottom-width: medium;
  border-bottom-style: none; }

#mobile-menu .prime-nav-mobile-list li a.dropdown-link {
  border-width: medium medium 1px;
  border-style: none none solid;
  border-color: currentColor currentColor rgba(0, 0, 0, 0.6);
  padding: 0;
  border-radius: 100% !important;
  border-image: none;
  left: inherit;
  top: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  right: 15px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 30px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  z-index: 9999;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.16);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.09) !important;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.16);
  -moz-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.16); }

#mobile-menu .prime-nav-mobile-list li a.dropdown-link:hover {
  border-width: medium medium 1px;
  border-style: none none solid;
  border-color: currentColor currentColor rgba(0, 0, 0, 0.6);
  padding: 0;
  border-radius: 100% !important;
  border-image: none;
  left: inherit;
  top: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  right: 15px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 30px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  z-index: 9999;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.16);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.09) !important;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.16);
  -moz-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.16); }

#mobile-menu .prime-nav-mobile-list li a.icon-caret-up.dropdown-link {
  border-bottom-color: rgba(255, 255, 255, 0.26);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.9);
  background-color: rgba(0, 0, 0, 0.4) !important;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.9);
  -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.9); }

#mobile-menu::after {
  top: 0;
  width: 1px;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  z-index: 9999;
  content: "";
  box-shadow: 0 0 15px 3px black;
  background-color: black;
  -webkit-box-shadow: 0 0 15px 3px black;
  -moz-box-shadow: 0 0 15px 3px black; }

#mobile-menu .dropdown-menu {
  margin: 0;
  padding: 0;
  border-radius: 0;
  left: inherit;
  top: inherit;
  right: inherit;
  bottom: inherit;
  border-top-color: rgba(0, 0, 0, 0.6);
  border-top-width: 1px;
  border-top-style: solid;
  float: none;
  position: inherit;
  box-shadow: none;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

#mobile-menu .dropdown-menu li {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

#mobile-menu .dropdown-menu li:last-child {
  border-bottom-color: currentColor;
  border-bottom-width: medium;
  border-bottom-style: none; }

#mobile-menu .dropdown-menu > li > a {
  margin: 0;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.dropcap {
  border-radius: 2px;
  width: 34px;
  height: 34px;
  text-align: center;
  text-transform: uppercase;
  line-height: 34px;
  overflow: hidden;
  font-size: 1.6em;
  font-weight: bold;
  margin-right: 0.5em;
  float: left;
  display: block;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px; }

.large.dropcap {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 2em;
  margin-right: 0.5em; }

.rounded.dropcap {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%; }

.blue.dropcap {
  color: white;
  font-weight: lighter;
  background-color: #4daedd; }

.blue-text.dropcap {
  color: #4daedd;
  background-color: transparent; }

.green.dropcap {
  color: white;
  font-weight: lighter;
  background-color: #89ab46; }

.green-text.dropcap {
  color: #89ab46;
  background-color: transparent; }

.red.dropcap {
  color: white;
  font-weight: lighter;
  background-color: #c51c19; }

.red-text.dropcap {
  color: #c51c19;
  background-color: transparent; }

.orange.dropcap {
  color: white;
  font-weight: lighter;
  background-color: #df8f11; }

.orange-text.dropcap {
  color: #df8f11;
  background-color: transparent; }

.gray.dropcap {
  color: black;
  font-weight: lighter;
  background-color: #e8e8e8; }

.gray-text.dropcap {
  color: #bbbbbb;
  background-color: transparent; }

.black.dropcap {
  color: white;
  font-weight: lighter;
  background-color: black; }

.one-half {
  width: 48%; }

.one-third {
  width: 30.66%; }

.two-third {
  width: 65.33%; }

.one-fourth {
  width: 22%; }

.three-fourth {
  width: 74%; }

.one-fifth {
  width: 16.8%; }

.two-fifth {
  width: 37.6%; }

.three-fifth {
  width: 58.4%; }

.four-fifth {
  width: 67.2%; }

.one-sixth {
  width: 13.33%; }

.five-sixth {
  width: 82.67%; }

.one-half {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.one-third {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.two-third {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.three-fourth {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.one-fourth {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.one-fifth {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.two-fifth {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.three-fifth {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.four-fifth {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.one-sixth {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.five-sixth {
  margin-right: 4%;
  margin-bottom: 20px !important;
  float: left;
  position: relative; }

.column-last {
  clear: right;
  margin-right: 0 !important; }

ul.sc-list li {
  padding-left: 0;
  list-style-type: none; }

ul.sc-list li {
  background-position: left;
  padding-left: 30px;
  list-style-type: none;
  background-repeat: no-repeat; }

ul.accept.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/accept.png"); }

ul.add.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/add.png"); }

ul.android.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/android.png"); }

ul.apple_corp.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/apple_corp.png"); }

ul.arrow_right.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/arrow_right.png"); }

ul.award_star_bronze_2.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/award_star_bronze_2.png"); }

ul.brick.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/brick.png"); }

ul.bullet_add.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_add.png"); }

ul.bullet_arrow_right.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_arrow_right.png"); }

ul.bullet_black.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_black.png"); }

ul.bullet_blue.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_blue.png"); }

ul.bullet_delete.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_delete.png"); }

ul.bullet_error.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_error.png"); }

ul.bullet_go.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_go.png"); }

ul.bullet_green.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_green.png"); }

ul.bullet_orange.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_orange.png"); }

ul.bullet_pink.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_pink.png"); }

ul.bullet_purple.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_purple.png"); }

ul.bullet_red.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_red.png"); }

ul.bullet_star.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_star.png"); }

ul.bullet_toggle_plus.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_toggle_plus.png"); }

ul.bullet_white.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_white.png"); }

ul.bullet_yellow.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/bullet_yellow.png"); }

ul.check_box.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/check_box.png"); }

ul.color_management.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/color_management.png"); }

ul.color_swatch.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/color_swatch.png"); }

ul.color_wheel.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/color_wheel.png"); }

ul.comment.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/comment.png"); }

ul.comments.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/comments.png"); }

ul.control_play_blue.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/control_play_blue.png"); }

ul.delete.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/delete.png"); }

ul.draw_star.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/draw_star.png"); }

ul.email.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/email.png"); }

ul.emotion_evilgrin.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/emotion_evilgrin.png"); }

ul.emotion_grin.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/emotion_grin.png"); }

ul.emotion_happy.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/emotion_happy.png"); }

ul.emotion_smile.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/emotion_smile.png"); }

ul.emotion_suprised.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/emotion_suprised.png"); }

ul.emotion_tongue.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/emotion_tongue.png"); }

ul.emotion_unhappy.sc-list li {
  background-image: url("../images/bluebox/fatcow-hosting-icons-2400/16x16/emotion_unhappy.png"); }

ul.emotion_waii.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/emotion_waii.png"); }

ul.emotion_wink.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/emotion_wink.png"); }

ul.error.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/error.png"); }

ul.exclamation.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/exclamation.png"); }

ul.feed.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/feed.png"); }

ul.flag_1.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/flag_1.png"); }

ul.flag_2.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/flag_2.png"); }

ul.flag_green.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/flag_green.png"); }

ul.flag_hot.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/flag_hot.png"); }

ul.flag_yellow.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/flag_yellow.png"); }

ul.green.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/green.png"); }

ul.hand_point.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/hand_point.png"); }

ul.heart.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/heart.png"); }

ul.help.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/help.png"); }

ul.image.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/image.png"); }

ul.information.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/information.png"); }

ul.key.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/key.png"); }

ul.life_vest.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/life_vest.png"); }

ul.lightning.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/lightning.png"); }

ul.medal_bronze_2.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/medal_bronze_2.png"); }

ul.monitor.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/monitor.png"); }

ul.page_white.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/page_white.png"); }

ul.printer.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/printer.png"); }

ul.resultset_next.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/resultset_next.png"); }

ul.ribbon.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/ribbon.png"); }

ul.rosette.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/rosette.png"); }

ul.shopping.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/shopping.png"); }

ul.sign.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/sign.png"); }

ul.star.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/star.png"); }

ul.statistics.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/statistics.png"); }

ul.support.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/support.png"); }

ul.tag_blue.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/tag_blue.png"); }

ul.tag_green.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/tag_green.png"); }

ul.tag_orange.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/tag_orange.png"); }

ul.tag_pink.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/tag_pink.png"); }

ul.tag_purple.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/tag_purple.png"); }

ul.tag_red.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/tag_red.png"); }

ul.tag_yellow.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/tag_yellow.png"); }

ul.tick.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/tick.png"); }

ul.time.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/time.png"); }

ul.zoom.sc-list li {
  background-image: url("../images/blueboxx/fatcow-hosting-icons-2400/16x16/zoom.png"); }

div.msg {
  padding: 20px 40px 20px 20px;
  border-radius: 0;
  border: 1px solid #ced99c;
  border-image: none;
  color: black;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
  background-image: -ms-linear-gradient(bottom, #fdffc4 0%, #fdffca 100%);
  background-repeat: repeat-x;
  background-color: #fdffca;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset;
  -moz-text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
  -webkit-text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25); }

div.msg p {
  margin: 10px 50px 10px 10px; }

div.error {
  border: 1px solid #ebb9b9;
  border-image: none;
  color: #610500;
  background-image: -ms-linear-gradient(bottom, #fed2d2 0%, #fed7d7 100%);
  background-color: #fed7d7;
  -moz-text-shadow: 0 1px 0 #fbbebe;
  -webkit-text-shadow: 0 1px 0 #fbbebe; }

div.success {
  border: 1px solid #b4d0b9;
  border-image: none;
  color: #0c4e17;
  background-image: -ms-linear-gradient(bottom, #c8f5d0 0%, #cef6d6 100%);
  background-color: #cef6d5;
  -moz-text-shadow: 0 1px 0 #befbc7;
  -webkit-text-shadow: 0 1px 0 #befbc7; }

div.info {
  border: 1px solid #add6df;
  border-image: none;
  color: #0c344e;
  background-image: -ms-linear-gradient(bottom, #c8edf5 0%, #ceeff6 100%);
  background-color: #ceeff6;
  -moz-text-shadow: 0 1px 0 #b5dbfa;
  -webkit-text-shadow: 0 1px 0 #b5dbfa; }

.close {
  top: 20px;
  right: 20px;
  color: black;
  line-height: 13px;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  opacity: 0.2;
  text-shadow: 0 1px 0 #ffffff;
  -khtml-opacity: 0.2;
  -moz-opacity: 0.2; }

.close:hover {
  color: black;
  opacity: 0.4;
  -khtml-opacity: 0.4;
  -moz-opacity: 0.4; }

div.divide {
  margin: 2em 0;
  width: 100%;
  height: 3px;
  overflow: hidden;
  clear: both; }

div.divide-1.divide {
  background-position: left top;
  border-right-color: rgba(0, 0, 0, 0.16);
  border-left-color: rgba(0, 0, 0, 0.16);
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  background-image: url("../images/blueboxx/divide-1-bg.png");
  background-repeat: repeat-x; }

div.divide-2.divide {
  height: 4px;
  background-image: url("../images/blueboxx/divide-2-bg.png"); }

div.divide-3.divide {
  height: 5px;
  background-image: url("../images/blueboxx/divide-3-bg.png"); }

div.divide-4.divide {
  height: 1px;
  background-image: url("../images/blueboxx/divide-4-bg.png"); }

.button {
  background-position: left top;
  margin: 0 10px 20px 0;
  padding: 7px 12px;
  border-radius: 5px;
  border: 1px solid #3597c7;
  transition: none !important;
  border-image: none;
  color: rgba(255, 255, 255, 0.9) !important;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-image: url("../images/blueboxx/button-type-1-1-bg.jpg");
  background-repeat: repeat-x;
  background-color: #4daedd;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -o-border-radius: 5px;
  -moz-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -border-radius: 5px;
  -o-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15); }

.nivo-caption a {
  background-position: left top;
  margin: 0 10px 20px 0;
  padding: 7px 12px;
  border-radius: 5px;
  border: 1px solid #3597c7;
  transition: none !important;
  border-image: none;
  color: rgba(255, 255, 255, 0.9) !important;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-image: url("../images/blueboxx/button-type-1-1-bg.jpg");
  background-repeat: repeat-x;
  background-color: #4daedd;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -o-border-radius: 5px;
  -moz-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -border-radius: 5px;
  -o-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15); }

div.layout_element_quote_block div.messages div.button-wrap > a {
  background-position: left top;
  margin: 0 10px 20px 0;
  padding: 7px 12px;
  border-radius: 5px;
  border: 1px solid #3597c7;
  transition: none !important;
  border-image: none;
  color: rgba(255, 255, 255, 0.9) !important;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-image: url("../images/blueboxx/button-type-1-1-bg.jpg");
  background-repeat: repeat-x;
  background-color: #4daedd;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -o-border-radius: 5px;
  -moz-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -border-radius: 5px;
  -o-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15); }

.large.button {
  padding: 14px 24px;
  border-radius: 8px;
  font-size: 14px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -o-border-radius: 8px;
  -border-radius: 8px; }

.rounded.button {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -o-border-radius: 50px;
  -border-radius: 50px; }

.button:hover {
  background-position: left -50px;
  color: white !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }

.nivo-caption a:hover {
  background-position: left -50px;
  color: white !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }

div.layout_element_quote_block div.messages div.button-wrap > a:hover {
  background-position: left -50px;
  color: white !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }

.button:active {
  background-position: left -100px;
  color: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -o-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2); }

#button-submit:active {
  background-position: left -100px;
  color: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -o-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2); }

.nivo-caption a:active {
  background-position: left -100px;
  color: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -o-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2); }

div.layout_element_quote_block div.messages div.button-wrap > a:active {
  background-position: left -100px;
  color: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2);
  -o-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.2); }

.type-1-2.button {
  border: 1px solid #d9d9d9;
  border-image: none;
  color: rgba(0, 0, 0, 0.9) !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  text-shadow: none;
  background-image: url("../images/blueboxx/button-type-1-2-bg.jpg");
  background-color: #f6f6f6;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  -moz-text-shadow: none;
  -webkit-text-shadow: none;
  -o-text-shadow: none;
  -o-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05); }

.type-1-2.button:hover {
  color: black !important; }

.type-1-3.button {
  border: 1px solid #c51c19;
  border-image: none;
  background-image: url("../images/blueboxx/button-type-1-3-bg.jpg");
  background-color: #e03633; }

.type-1-4.button {
  border: 1px solid #df8f11;
  border-image: none;
  background-image: url("../images/blueboxx/button-type-1-4-bg.jpg");
  background-color: #f1a020; }

.nivo-caption a {
  border: 1px solid #df8f11;
  border-image: none;
  background-image: url("../images/blueboxx/button-type-1-4-bg.jpg");
  background-color: #f1a020; }

.type-1-5.button {
  border: 1px solid #89ab46;
  border-image: none;
  background-image: url("../images/blueboxx/button-type-1-5-bg.jpg");
  background-color: #9abe57; }

.type-1-6.button {
  border: 1px solid black;
  border-image: none;
  background-image: url("../images/blueboxx/button-type-1-6-bg.jpg");
  background-color: #434343; }

.magnifying-glass-helper .magnifying-glass {
  border-radius: 100%;
  cursor: none;
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  background-color: white;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -webkit-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25); }

.bluebox-custom-social-link {
  margin: 0 0 10px 8px;
  padding: 0;
  border-radius: 6px;
  width: 30px !important;
  text-align: center;
  color: #22a9f7;
  line-height: 30px !important;
  display: inline-block !important;
  position: relative;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: white;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16); }

.bluebox-custom-social-link:hover {
  color: #22a9f7;
  opacity: 0.6; }

@media all and (min-width: 767px) {
  .row .right-sidebar.span3 {
    width: 28% !important;
    margin-left: 6% !important; }
  body.l-c .row .left-sidebar.span3 {
    width: 28% !important;
    margin-right: 6% !important; }
  body.l-c .row .span8.main-content {
    margin-left: 0 !important; } }

@media all and (max-width: 979px) {
  .bluebox-head-bottom {
    display: none !important; }
  .bluebox-head-details {
    display: none !important; }
  .bluebox-slider-content .slide-details .details-wrap .details-content p {
    display: none !important; }
  .nova-right-tabs-slider .bluebox-slider-content .slide-details {
    display: none !important; }
  .prime-nav {
    display: none !important; }
  .bluebox-builder-row {
    padding: 0 20px;
    margin-top: 30px; }
  .bluebox-footer-content {
    padding-right: 10px;
    padding-left: 10px; }
  .bluebox-head-top {
    text-align: center;
    position: relative; }
  .twitter-tweets-layout-element.gumm-layout-element {
    display: none !important; }
  .bluebox-head-top .bluebox-head-logo {
    float: none;
    display: inline-block; }
  a.mobile-nav-button {
    left: 10px;
    top: 50%;
    width: 60px;
    height: 30px;
    text-align: center;
    padding-top: 10px;
    margin-top: -20px;
    display: inline-block;
    position: absolute;
    z-index: 300; }
  a.mobile-nav-button span {
    margin: 0 auto 4px;
    width: 32px;
    height: 4px;
    display: block;
    background-color: #ed7721; }
  .bluebox-slider-content .slide-details .details-wrap .details-content h2 {
    font-size: 18px; } }

@media all and (max-width: 767px) {
  body {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .iosSlider .slide-details {
    display: none !important; }
  .bluebox-info-bar {
    display: none !important; }
  .nova-slider-bottom-nav .bluebox-slider-content ul {
    display: none !important; }
  .nova-slider-four .bluebox-slider-content .heading-container {
    display: none !important; }
  .nova-rev-extend-button.roki-button-link {
    display: none !important; }
  .nova-rev-extend-button-light.roki-button-link {
    display: none !important; }
  .roki-rev-slide-extra-text-light {
    display: none !important; }
  .revolution-slider-layout-element .roki-button-link {
    display: none !important; }
  .tp-bullets {
    display: none !important; }
  .bluebox-copyrights-wrap {
    text-align: center;
    float: none; }
  .bluebox-authors-wrap .bluebox-authors-content {
    text-align: center;
    float: none; }
  .bluebox-authors-wrap .bluebox-authors-content {
    padding-left: 75px; }
  .bluebox-authors-wrap .bluebox-authors-content a.back-to-top {
    top: -20px;
    right: 20px; }
  .page-heading-wrap h6 {
    padding-left: 20px; }
  .page-heading-wrap h2 {
    padding-left: 20px; }
  .single-staff .bluebox-builder-row {
    margin-top: 0; }
  .bluebox-builder-row > .row > div {
    margin-top: 30px; }
  .gumm-filterable-item {
    margin-top: 30px; }
  .bluebox-footer-content > .row > div {
    margin-top: 30px; }
  .blog-layout-element .row > div {
    margin-top: 30px; }
  .bluebox-builder-row > .row > div:first-child {
    margin-top: 0; }
  :first-child.gumm-filterable-item {
    margin-top: 0; }
  .bluebox-footer-content > .row > div:first-child {
    margin-top: 0; }
  .blog-layout-element .row > div:first-child {
    margin-top: 0; }
  .bluebox-fancy-text {
    text-align: center !important; }
  .full-width.bluebox-fancy-text {
    padding: 26px 30px 27px !important; }
  .fancy-content {
    text-align: center;
    display: block !important; }
  .bluebox-fancy-text .button-container {
    margin-top: 18px !important;
    margin-right: auto !important;
    float: none;
    display: inline-block; }
  .blog-1-col .head-link {
    font-size: 18px; }
  .blog-1-col .head-link h4 {
    font-size: 18px; }
  ul.cols-2.bluebox-pricing-table > li {
    width: 100%; }
  ul.cols-3.bluebox-pricing-table > li {
    width: 100%; }
  ul.cols-4.bluebox-pricing-table > li {
    width: 100%; }
  ul.cols-5.bluebox-pricing-table > li {
    width: 100%; }
  ul.cols-6.bluebox-pricing-table > li {
    width: 100%; } }

.nav-tabs li .icon-chevron-down {
  display: none; }

.nav-tabs li.active .icon-chevron-down {
  display: inline-block; }

.nav-tabs li.active .icon-chevron-right {
  display: none; }

.flex-slider {
  position: relative; }

.flex-slider .slides {
  overflow: hidden; }

.loading.flex-slider {
  overflow: hidden; }

.loading.flex-slider .slides {
  visibility: hidden; }

.flex-slider .slides .slide-item {
  display: none; }

.flex-slider .slides :first-child.slide-item {
  display: block; }

.gumm-layout-element-slider .slides-container-wrapper {
  overflow: hidden; }

.gumm-layout-element-slider .slides-container {
  position: relative; }

.gumm-layout-element-slider .slides-container .hidden {
  display: none; }

.iosSlider {
  left: 0;
  top: 0;
  height: 1500px;
  overflow: hidden;
  position: relative; }

.iosSlider .slider {
  width: 100%;
  height: 100%;
  display: inline-block; }

.iosSlider .slider .slide {
  width: 100%;
  float: left; }

.iosSlider img {
  width: 100%; }

.iosSliderContainer {
  width: 100%;
  height: 0;
  position: relative; }

.loading.iosSlider .slider > *:first-child {
  display: block; }

.loading.iosSlider .slider > * {
  display: none; }

.loading.iosSlider .slide-details {
  visibility: hidden;
  opacity: 0; }

.iosSlider .slide-details {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity ease .3s; }

.wi-slider .slide-pagination {
  z-index: 99; }

.wi-container {
  position: relative;
  z-index: 50;
  perspective: 1400px;
  -webkit-perspective: 1400px;
  -moz-perspective: 1400px;
  -o-perspective: 1400px; }

.wi-container > * {
  margin: 0;
  left: 0;
  top: 0;
  height: 100%;
  right: 0;
  bottom: 0;
  display: none;
  position: relative;
  box-sizing: border-box;
  pointer-events: auto;
  background-color: #fafafa;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box; }

.wi-container > *:first-child {
  display: block; }

.wi-container > .wi-move {
  transition: transform 500ms, opacity 500ms, left 500ms, top 500ms;
  pointer-events: none;
  -webkit-transition: -webkit-transform 500ms ease, opacity 500ms ease, left 500ms ease, top 500ms ease;
  -moz-transition: -moz-transform 500ms ease, opacity 500ms ease, left 500ms ease, top 500ms ease;
  -o-transition: -o-transform 500ms ease, opacity 500ms ease, left 500ms ease, top 500ms ease; }

.canvas-gauge-element {
  line-height: 0;
  display: inline-block;
  position: relative; }

.canvas-text-box {
  position: absolute; }

.text-box-style-five.bluebox-textboxes {
  text-align: center; }

.text-box-style-six.bluebox-textboxes .bb-gauge-icon-container {
  text-align: center;
  display: block; }

.text-box-style-one.bluebox-textboxes.text-box-gauge .bb-gauge-icon-container {
  margin-right: 18px;
  float: left; }

.text-box-style-two.bluebox-textboxes.text-box-gauge .bb-gauge-icon-container {
  margin-right: 18px;
  float: left; }

.type-centered.bluebox-header .bluebox-head-top {
  text-align: center; }

.type-centered.bluebox-header .bluebox-head-top .bluebox-head-logo {
  float: none;
  display: inline-block; }

.gmap-container img {
  max-width: none !important; }

.vCard.staff-layout-element .row.gumm-filterable-item {
  margin: 0 0 30px;
  position: relative;
  -webkit-backface-visibility: hidden; }

.embeddedVideoWrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative; }

.embeddedVideoWrapper iframe {
  left: 0;
  top: 0;
  position: absolute; }

.embeddedVideoWrapper object {
  left: 0;
  top: 0;
  position: absolute; }

body.search-no-results div.error {
  margin: 80px 0 !important; }

.bluebox-single-staff-wrap {
  width: 100%;
  float: left; }

.bluebox-single-staff-wrap .top-staff-wrap {
  width: 100%;
  float: left;
  position: relative;
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

.bluebox-single-staff-wrap .single-staff-left-wrap {
  width: 25.5%;
  float: left;
  position: relative; }

.bluebox-single-staff-wrap .top-staff-wrap .single-staff-left-wrap::before {
  top: 0;
  width: 4px;
  right: -2px;
  bottom: 0;
  position: absolute;
  z-index: 2;
  content: "";
  background-color: #ed7721; }

.bluebox-single-staff-wrap .top-staff-wrap .single-staff-left-wrap::after {
  top: 30px;
  width: 0;
  height: 0;
  right: -8px;
  margin-top: -7px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #ed7721;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 2;
  content: ""; }

.bluebox-single-staff-wrap .bottom-staff-wrap .single-staff-left-wrap {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-single-staff-wrap .bottom-staff-wrap .single-staff-left-wrap {
  padding-top: 45px;
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-single-staff-wrap .bottom-staff-wrap .single-staff-left-wrap .single-social-link {
  padding: 0 0 0 60px;
  line-height: 45px;
  margin-top: 1px;
  display: block;
  position: relative;
  min-height: 45px; }

.bluebox-single-staff-wrap .bottom-staff-wrap .single-staff-left-wrap .single-social-link span {
  left: 0;
  top: 0;
  width: 45px;
  height: 45px;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  line-height: 45px;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-single-staff-wrap .bottom-staff-wrap .single-staff-left-wrap .single-social-link:hover span {
  color: white;
  background-color: #ed7721; }

.bluebox-single-staff-wrap .single-staff-right-wrap {
  width: 74.5%;
  float: left; }

.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap {
  min-height: 60px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap h2 {
  margin: 18px 30px;
  width: auto; }

.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap h2 span {
  font-size: 18px; }

.bluebox-single-staff-wrap .single-staff-right-wrap .single-staff-info {
  padding: 22px 30px; }

.bluebox-single-staff-wrap .single-staff-right-wrap .staff-extra-details {
  width: 100%;
  float: left; }

.bluebox-single-staff-wrap .single-staff-right-wrap .staff-extra-details .term {
  padding: 20px 0 0;
  line-height: 20px; }

.bluebox-single-staff-wrap .single-staff-right-wrap .staff-extra-details .description {
  padding: 20px 0 0;
  line-height: 20px; }

.bluebox-single-staff-wrap .single-staff-right-wrap .staff-extra-details .term {
  width: 35%;
  float: left; }

.bluebox-single-staff-wrap .single-staff-right-wrap .staff-extra-details .description {
  width: 65%;
  float: left; }

.bluebox-single-staff-wrap .single-staff-right-wrap .staff-extra-details .term strong {
  margin: 0 30px;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block; }

.bluebox-single-staff-wrap .single-staff-right-wrap .staff-extra-details .term span {
  font-size: 13px;
  margin-right: 10px;
  display: inline-block; }

.new-builder-element.bluebox-single-staff-wrap .top-staff-wrap {
  background-color: transparent; }

.new-builder-element.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap {
  background-color: rgba(0, 0, 0, 0.02); }

.new-builder-element.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap .staff-more-link {
  top: 0;
  width: 60px;
  height: 61px;
  text-align: center;
  right: 0;
  line-height: 62px;
  font-size: 16px;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.new-builder-element.bluebox-single-staff-wrap:hover .single-staff-right-wrap .page-heading-wrap .staff-more-link {
  color: white;
  background-color: rgba(0, 0, 0, 0.74); }

.new-builder-element.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap .staff-more-link:hover {
  color: white;
  background-color: #ed7721; }

.new-builder-element.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap h2 {
  font-size: 14px; }

.new-builder-element.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap h2 span {
  font-size: 13px; }

.cal-page-month-wrap {
  height: 60px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

.cal-page-month-wrap h3 {
  margin: 0;
  padding: 20px 80px;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px;
  font-weight: normal;
  display: block; }

.cal-page-month-wrap a.nav-arrow {
  top: 0;
  width: 60px;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  line-height: 61px;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.cal-page-month-wrap a.nav-arrow:hover {
  color: white;
  background-color: #ed7721; }

.cal-page-month-wrap a.icon-chevron-left.nav-arrow {
  left: 0; }

.cal-page-month-wrap a.icon-chevron-right.nav-arrow {
  right: 0; }

.bluebox-events-list {
  width: 100%;
  margin-bottom: 30px;
  float: left;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

:last-child.bluebox-events-list {
  margin: 0 0 25px; }

.bluebox-events-list .event-date-line {
  left: 0;
  top: 0;
  width: 60px;
  bottom: 0;
  color: rgba(0, 0, 0, 0.5);
  float: left;
  display: block;
  position: absolute;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04); }

.bluebox-events-list:hover .event-date-line {
  color: white;
  background-color: #ed7721; }

.bluebox-events-list .event-date-line::before {
  top: 0;
  width: 4px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  content: "";
  background-color: black; }

.bluebox-events-list .event-date-line::after {
  top: 50%;
  width: 0;
  height: 0;
  right: -6px;
  margin-top: -7px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: black;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 2;
  content: ""; }

.bluebox-events-list:hover .event-date-line::before {
  background-color: #ed7721; }

.bluebox-events-list:hover .event-date-line::after {
  border-left-color: #ed7721;
  border-left-width: 6px;
  border-left-style: solid; }

.bluebox-events-list .event-date-line .date-details-wrap {
  width: 56px;
  text-align: center;
  float: left; }

.bluebox-events-list .event-date-line .date-details-wrap strong {
  width: 56px;
  color: black;
  line-height: 20px;
  font-size: 18px;
  margin-top: 12px;
  display: inline-block; }

.bluebox-events-list:hover .event-date-line .date-details-wrap strong {
  color: white; }

.bluebox-events-list .event-details {
  margin-left: 60px; }

.bluebox-events-list .event-details .event-content-wrap {
  width: 100%;
  float: left; }

.bluebox-events-list .event-details .image-wrap {
  width: 25%;
  float: left; }

.no-image.bluebox-events-list .event-details .image-wrap {
  display: none; }

.bluebox-events-list .event-details .image-details::after {
  border-width: 1px 1px 1px medium;
  border-style: solid solid solid none;
  border-color: rgba(0, 0, 0, 0.04) rgba(0, 0, 0, 0.04) rgba(0, 0, 0, 0.04) currentColor;
  border-image: none; }

.top-staff-wrap .image-wrap ul.social-links {
  margin: -22px 0 0;
  top: 50%;
  width: 100%;
  text-align: center;
  display: block;
  list-style-type: none;
  position: absolute;
  z-index: 3;
  opacity: 0; }

.top-staff-wrap .image-wrap ul.social-links li {
  margin: 0;
  text-align: center;
  float: none;
  display: inline-block; }

.top-staff-wrap .image-wrap ul.social-links li a {
  transition: color 0.2s linear;
  width: 45px;
  height: 45px;
  color: white;
  font-size: 24px;
  display: block;
  z-index: 3;
  -webkit-transition: color .2s linear;
  -moz-transition: color .2s linear;
  -o-transition: color .2s linear; }

.top-staff-wrap .image-wrap ul.social-links li a:hover {
  color: black; }

.top-staff-wrap:hover .image-wrap ul.social-links {
  margin-top: -12px;
  opacity: 1; }

.top-staff-wrap .image-wrap ul {
  transition: opacity 0.2s linear, margin-top 0.2s linear;
  -webkit-transition: opacity .2s linear, margin-top .2s linear;
  -moz-transition: opacity .2s linear, margin-top .2s linear;
  -o-transition: opacity .2s linear, margin-top .2s linear; }

.bluebox-staff .image-wrap a > i {
  left: 50%;
  top: 50%;
  color: white;
  font-size: 24px;
  margin-top: -22px;
  margin-left: -7px;
  position: absolute;
  z-index: 3;
  opacity: 0; }

.bluebox-staff .image-wrap:hover a > i {
  margin-top: -12px;
  opacity: 1; }

.bluebox-staff .image-wrap a > i {
  transition: opacity 0.2s linear, margin-top 0.2s linear;
  -webkit-transition: opacity .2s linear, margin-top .2s linear;
  -moz-transition: opacity .2s linear, margin-top .2s linear;
  -o-transition: opacity .2s linear, margin-top .2s linear; }

.bluebox-events-list .event-details .event-content {
  width: 75%;
  float: left; }

.no-image.bluebox-events-list .event-details .event-content {
  width: 100%; }

.bluebox-events-list .event-details .page-heading-wrap {
  border: currentColor;
  border-image: none;
  width: 100%;
  min-height: 60px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-events-list .event-details .page-heading-wrap h2 {
  margin: 18px 240px 18px 30px;
  width: auto; }

.bluebox-events-list .event-details .page-heading-wrap ul.event-rate-list {
  margin: 0;
  top: 0;
  height: 60px;
  right: 0;
  float: right;
  display: block;
  list-style-type: none;
  position: absolute; }

.bluebox-events-list .event-details .page-heading-wrap ul.event-rate-list li {
  width: 20px;
  height: 60px;
  text-align: center;
  line-height: 63px;
  font-size: 16px;
  margin-right: 20px;
  float: left;
  display: block; }

.bluebox-events-list .event-details .page-heading-wrap ul.event-rate-list li {
  color: rgba(0, 0, 0, 0.26); }

.bluebox-events-list:hover .event-details .page-heading-wrap ul.event-rate-list li {
  color: #ed7721; }

.bluebox-events-list .event-details .event-inner-content-wrap {
  padding: 22px 45px 22px 30px; }

.bluebox-events-list .event-details .event-inner-content-wrap .event-more-link {
  width: 45px;
  height: 45px;
  text-align: center;
  right: 0;
  bottom: 0;
  line-height: 46px;
  font-size: 16px;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-events-list:hover .event-details .event-inner-content-wrap .event-more-link {
  color: white;
  background-color: rgba(0, 0, 0, 0.74); }

.bluebox-events-list .event-details .event-inner-content-wrap .event-more-link:hover {
  color: white;
  background-color: #ed7721; }

.single-post.bluebox-events-list .event-details .event-content {
  width: 100%; }

.single-post.bluebox-events-list .event-details .event-inner-content-wrap {
  padding: 0;
  position: relative; }

.single-post.bluebox-events-list .event-details .event-inner-content-wrap div.single-event-image {
  width: 100%;
  float: left; }

.single-post.bluebox-events-list .event-details .event-inner-content-wrap div.single-event-image img {
  width: 100%; }

.single-post.bluebox-events-list .event-details .event-inner-content-wrap div.single-event-extra-info {
  padding: 22px 30px;
  float: left; }

.single-post.bluebox-events-list .event-date-line {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.02); }

.single-post.bluebox-events-list:hover .event-date-line {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.02); }

.single-post.bluebox-events-list:hover .event-date-line .date-details-wrap strong {
  color: black; }

.single-post.bluebox-events-list .event-date-line::after {
  display: none; }

.single-post.bluebox-events-list .event-date-line::before {
  display: none; }

.bluebox-new-blog-element {
  position: relative; }

.blog-layout-element > .row > .row > .blog-fancy-cols.row {
  margin-bottom: 30px; }

.blog-layout-element > .row > .row > :last-child.blog-fancy-cols.row {
  margin-bottom: 0; }

.bluebox-new-blog-element .blog-new-heading-wrap {
  left: 0;
  bottom: 0;
  position: absolute; }

.bluebox-new-blog-element .blog-new-heading-wrap > .inner-wrap {
  position: relative;
  z-index: 2; }

.bluebox-new-blog-element .blog-new-heading-wrap a.head-link {
  padding: 7px 15px 8px;
  color: white;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-new-blog-element .blog-new-heading-wrap a.head-link h4 {
  text-transform: none;
  line-height: 20px;
  font-size: 13px;
  font-weight: normal; }

.bluebox-new-blog-element .blog-new-heading-wrap > .inner-wrap .new-blog-date {
  padding: 7px 15px 8px;
  transition: background-color 0.2s linear, color 0.2s linear;
  left: 0;
  top: -36px;
  color: white;
  display: inline-block;
  position: absolute;
  z-index: 2;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.26);
  -webkit-transition: background-color .2s linear, color .2s linear;
  -moz-transition: background-color .2s linear, color .2s linear;
  -o-transition: background-color .2s linear, color .2s linear;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-new-blog-element:hover .blog-new-heading-wrap > .inner-wrap .new-blog-date {
  box-shadow: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.bluebox-new-blog-element .blog-new-post-format-icon {
  transition: background-color 0.2s linear, color 0.2s linear;
  top: 0;
  width: 45px;
  height: 45px;
  text-align: center;
  right: 0;
  color: white;
  line-height: 46px;
  font-size: 16px;
  display: block;
  position: absolute;
  z-index: 2;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.26);
  -webkit-transition: background-color .2s linear, color .2s linear;
  -moz-transition: background-color .2s linear, color .2s linear;
  -o-transition: background-color .2s linear, color .2s linear;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-new-blog-element:hover .blog-new-post-format-icon {
  box-shadow: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.bluebox-new-blog-element .image-wrap a > i {
  left: 50%;
  top: 50%;
  color: white;
  font-size: 24px;
  margin-top: -22px;
  margin-left: -7px;
  position: absolute;
  z-index: 3;
  opacity: 0; }

.bluebox-new-blog-element .image-wrap:hover a > i {
  margin-top: -12px;
  opacity: 1; }

.bluebox-new-blog-element .image-wrap a > i {
  transition: opacity 0.2s linear, margin-top 0.2s linear;
  -webkit-transition: opacity .2s linear, margin-top .2s linear;
  -moz-transition: opacity .2s linear, margin-top .2s linear;
  -o-transition: opacity .2s linear, margin-top .2s linear; }

.bluebox-new-tabs-element {
  width: 100%;
  float: left; }

.bluebox-new-tabs-element > .nav-left {
  width: 30%;
  float: left;
  position: relative;
  z-index: 3;
  background-color: white; }

.bluebox-new-tabs-element > .content-right {
  width: 70%;
  float: left;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-new-tabs-element > .content-right > .content {
  padding: 0 30px; }

.bluebox-new-tabs-element > .nav-left ul {
  margin: 0;
  padding: 0;
  display: block;
  list-style-type: none;
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-new-tabs-element > .nav-left ul li {
  margin-bottom: 0;
  display: block;
  position: relative; }

.bluebox-new-tabs-element > .nav-left ul li > a {
  padding: 7px 20px 8px 55px;
  line-height: 20px;
  display: block;
  position: relative;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04); }

.bluebox-new-tabs-element > .nav-left ul li:first-child > a:hover {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06); }

.bluebox-new-tabs-element > .nav-left ul li:first-child > a {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06); }

.bluebox-new-tabs-element > .nav-left ul li:first-child.active > a {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(0, 0, 0, 0.06); }

.bluebox-new-tabs-element > .nav-left ul li > a:hover {
  color: #ed7721;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04);
  background-color: white;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04); }

.bluebox-new-tabs-element > .nav-left ul li.active > a {
  color: #ed7721;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04);
  background-color: white;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06), inset -4px 0 0 0 rgba(0, 0, 0, 0.04); }

.bluebox-new-tabs-element > .nav-left ul li.active > a {
  color: #ed7721 !important;
  font-weight: 700; }

.bluebox-new-tabs-element > .nav-left ul li > a > span {
  left: 0;
  top: 0;
  width: 35px;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  line-height: 36px;
  display: block;
  position: absolute;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

.bluebox-new-tabs-element > .nav-left ul li:first-child > a > span {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.bluebox-new-tabs-element > .nav-left ul li > a:hover > span {
  color: black; }

.bluebox-new-tabs-element > .nav-left ul li.active > a > span {
  top: -1px;
  color: white;
  background-color: #ed7721; }

.bluebox-new-tabs-element > .nav-left ul li.active > a::before {
  left: 30px;
  top: 0;
  width: 4px;
  bottom: 0;
  position: absolute;
  z-index: 10;
  content: "";
  background-color: #ed7721; }

.bluebox-new-tabs-element > .nav-left ul li.active > a::after {
  top: 0;
  width: 4px;
  right: 0;
  bottom: 1px;
  position: absolute;
  z-index: 10;
  content: "";
  background-color: white; }

.bluebox-new-tabs-element > .nav-left ul li.active::before {
  left: 34px;
  top: 13px;
  width: 0;
  height: 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #ed7721;
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 11;
  content: ""; }

.bluebox-new-tabs-element > .nav-left ul .sub-menu {
  margin-left: 0; }

.bluebox-new-tabs-element > .nav-left ul .sub-menu li a {
  color: rgba(0, 0, 0, 0.5);
  padding-left: 40px;
  font-size: 12px; }

.bluebox-new-tabs-element > .nav-left ul .sub-menu li a:hover {
  color: #ed7721; }

.medium.bluebox-new-tabs-element > .nav-left ul li > a {
  padding: 20px 20px 20px 80px;
  text-transform: uppercase;
  font-size: 14px; }

.medium.bluebox-new-tabs-element > .nav-left ul li > a > span {
  width: 60px;
  line-height: 61px; }

.medium.bluebox-new-tabs-element > .nav-left ul li.active > a::before {
  left: 56px; }

.medium.bluebox-new-tabs-element > .nav-left ul li.active::before {
  left: 60px;
  top: 25px; }

.large.bluebox-new-tabs-element > .nav-left {
  width: 90px; }

.large.bluebox-new-tabs-element > .content-right {
  width: auto;
  margin-left: 90px;
  float: none; }

.large.bluebox-new-tabs-element > .nav-left ul li > a {
  padding: 0;
  height: 90px;
  text-transform: uppercase;
  font-size: 14px; }

.large.bluebox-new-tabs-element > .nav-left ul li > a > span {
  width: 90px;
  line-height: 91px;
  box-shadow: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.large.bluebox-new-tabs-element > .nav-left ul li > a:hover > span {
  width: 90px;
  line-height: 91px;
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  background-color: transparent;
  -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06); }

.large.bluebox-new-tabs-element > .nav-left ul li.active > a > span {
  top: -1px;
  color: white;
  background-color: #ed7721; }

.large.bluebox-new-tabs-element > .nav-left ul li.active > a::before {
  left: 86px;
  z-index: 11; }

.large.bluebox-new-tabs-element > .nav-left ul li.active::before {
  left: 90px;
  top: 40px; }

div.calendar-page.note-design-wrap div.content {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

div.note-design-wrap div.content {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

div.calendar-page.note-design-wrap table th {
  padding: 20px 0; }

div.note-design-wrap table td {
  width: 14%; }

div.calendar-page.note-design-wrap td a {
  padding: 35% 0; }

div.note-design-wrap {
  position: relative; }

div.note-design-wrap div.content {
  margin: 0 1px;
  position: relative;
  z-index: 15;
  background-color: white; }

div.note-design-wrap table {
  width: 100%;
  text-align: center;
  border-collapse: collapse !important;
  border-spacing: 0 !important; }

div.note-design-wrap table tr {
  width: 100%;
  text-align: center;
  border-collapse: collapse !important;
  border-spacing: 0 !important; }

div.note-design-wrap table th {
  padding: 10px 0 15px;
  text-transform: uppercase;
  font-weight: normal;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06); }

div.note-design-wrap table td {
  width: 14%;
  font-size: 14px; }

div.note-design-wrap td a {
  padding: 18% 0;
  color: rgba(0, 0, 0, 0.5);
  display: block; }

div.note-design-wrap td a:hover {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: white;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

div.note-design-wrap td.off a {
  color: rgba(0, 0, 0, 0.5);
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

div.note-design-wrap td.event a {
  font-weight: bold;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

div.note-design-wrap td.event a:hover {
  color: white;
  background-color: #ed7721; }

div.note-design-wrap td.active a {
  color: white;
  font-weight: bold;
  background-color: #ed7721; }

div.note-design-wrap td.active.event a {
  color: white;
  font-weight: bold;
  background-color: #ed7721; }

div.note-design-wrap td.active a:hover {
  color: white;
  background-color: #ed7721; }

div.note-design-wrap td.active.event a:hover {
  color: white;
  background-color: #ed7721; }

.sidebar .month-heading {
  height: 45px;
  text-align: center;
  line-height: 45px;
  margin-bottom: 20px;
  display: block;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

.sidebar .month-heading h4 {
  margin: 0 60px;
  height: 45px;
  text-transform: uppercase;
  line-height: 43px;
  padding-top: 0;
  font-size: 14px;
  font-weight: normal;
  display: block; }

.sidebar div.note-design-wrap div.content {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.02); }

.sidebar div.note-design-wrap table td {
  font-size: 12px; }

.sidebar div.note-design-wrap .month-heading a.arrow {
  top: 0;
  width: 45px;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  line-height: 45px;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.sidebar div.note-design-wrap .month-heading a.arrow:hover {
  color: white;
  background-color: #ed7721; }

.sidebar div.note-design-wrap .month-heading a.icon-chevron-left.arrow {
  left: 0; }

.sidebar div.note-design-wrap .month-heading a.icon-chevron-right.arrow {
  right: 0; }

ul.detached {
  padding: 20px;
  min-height: 20px;
  background-color: white; }

ul.terms-alphabet {
  margin: 0 0 20px;
  height: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  display: block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.04); }

ul.detached.terms-alphabet {
  margin-left: 0; }

ul.terms-alphabet li {
  text-align: center;
  font-size: 11px;
  margin-right: 4px;
  margin-bottom: 4px;
  float: left;
  display: block;
  list-style-type: none; }

ul.terms-alphabet li a {
  width: 30px;
  height: 30px;
  text-transform: uppercase;
  line-height: 30px;
  font-weight: bold;
  text-decoration: none;
  display: block;
  background-color: white; }

ul.terms-alphabet li a:hover {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

ul.terms-alphabet li.no-terms a {
  color: rgba(0, 0, 0, 0.3);
  font-weight: normal;
  cursor: default;
  box-shadow: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

ul.terms-alphabet li.selected a {
  color: white;
  font-weight: bold;
  background-color: #ed7721; }

.a-to-z-terms.table-striped {
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06); }

.a-to-z-terms.table-striped tbody tr td {
  border-top-color: rgba(0, 0, 0, 0.02);
  border-top-width: 1px;
  border-top-style: solid; }

.a-to-z-terms.table-striped tbody tr td.first-letter {
  text-align: center;
  color: rgba(0, 0, 0, 0.16);
  text-transform: uppercase;
  padding-top: 30px;
  font-size: 48px;
  border-right-color: rgba(0, 0, 0, 0.02);
  border-right-width: 1px;
  border-right-style: solid;
  background-color: rgba(0, 0, 0, 0.02); }

.a-to-z-terms.table-striped tbody tr td.poker-term {
  padding: 20px 14px 20px 20px;
  border-right-color: rgba(0, 0, 0, 0.02);
  border-right-width: 1px;
  border-right-style: solid; }

.a-to-z-terms.table-striped tbody tr td.poker-term p {
  font-weight: bold;
  margin-bottom: 0; }

.a-to-z-terms.table-striped tbody tr td.term-meaning {
  padding: 20px 14px 20px 20px; }

.a-to-z-terms.table-striped tbody tr td.term-meaning p {
  color: rgba(0, 0, 0, 0.5);
  line-height: 21px;
  margin-bottom: 0;
  display: block; }

@media all and (min-width: 1600px) {
  ul.terms-alphabet {
    height: 36px; }
  ul.terms-alphabet li {
    font-size: 11px;
    margin-right: 0;
    margin-bottom: 0; }
  ul.terms-alphabet li a {
    width: 34px;
    height: 34px;
    line-height: 34px; } }

@media all and (max-width: 1400px) {
  ul.terms-alphabet {
    height: 30px; }
  ul.terms-alphabet li {
    font-size: 11px;
    margin-right: 1px;
    margin-bottom: 1px; }
  ul.terms-alphabet li a {
    width: 30px;
    height: 30px;
    line-height: 30px; } }

@media all and (max-width: 1300px) {
  ul.terms-alphabet {
    height: 24px; }
  ul.terms-alphabet li {
    font-size: 11px;
    margin-right: 1px;
    margin-bottom: 1px; }
  ul.terms-alphabet li a {
    width: 24px;
    height: 24px;
    line-height: 24px; } }

@media all and (max-width: 1099px) {
  ul.terms-alphabet {
    height: 20px; }
  ul.terms-alphabet li {
    font-size: 11px;
    margin-right: 1px;
    margin-bottom: 1px; }
  ul.terms-alphabet li a {
    width: 20px;
    height: 20px;
    line-height: 20px; }
  .bluebox-events-list .event-details .image-wrap {
    width: 30%; }
  .bluebox-single-staff-wrap .single-staff-left-wrap {
    width: 30%; }
  .bluebox-events-list .event-details .event-content {
    width: 70%; }
  .bluebox-single-staff-wrap .single-staff-right-wrap {
    width: 70%; } }

@media all and (max-width: 970px) {
  ul.terms-alphabet {
    height: 63px; }
  ul.terms-alphabet li {
    font-size: 11px;
    margin-right: 2px;
    margin-bottom: 2px; }
  ul.terms-alphabet li a {
    width: 30px;
    height: 30px;
    line-height: 30px; }
  .bluebox-events-list .event-details .image-wrap {
    width: 40%; }
  .bluebox-single-staff-wrap .single-staff-left-wrap {
    width: 40%; }
  .bluebox-events-list .event-details .event-content {
    width: 60%; }
  .bluebox-single-staff-wrap .single-staff-right-wrap {
    width: 60%; } }

@media all and (max-width: 800px) {
  .bluebox-events-list .event-details .image-wrap {
    width: 50%; }
  .bluebox-single-staff-wrap .single-staff-left-wrap {
    width: 50%; }
  .bluebox-events-list .event-details .event-content {
    width: 50%; }
  .bluebox-single-staff-wrap .single-staff-right-wrap {
    width: 50%; } }

@media all and (max-width: 700px) {
  .bluebox-events-list .event-details .image-wrap {
    display: none; }
  .bluebox-single-staff-wrap .single-staff-left-wrap {
    display: none; }
  .bluebox-events-list .event-details .event-content {
    width: 100%; }
  .bluebox-single-staff-wrap .single-staff-right-wrap {
    width: 100%; } }

@media all and (max-width: 500px) {
  ul.terms-alphabet {
    height: 45px; }
  ul.terms-alphabet li {
    font-size: 11px;
    margin-right: 1px;
    margin-bottom: 1px; }
  ul.terms-alphabet li a {
    width: 22px;
    height: 22px;
    line-height: 22px; } }

@media all and (max-width: 400px) {
  ul.terms-alphabet {
    height: 61px; }
  ul.terms-alphabet li {
    font-size: 11px;
    margin-right: 1px;
    margin-bottom: 1px; }
  ul.terms-alphabet li a {
    width: 20px;
    height: 20px;
    line-height: 20px; } }

.sticky {
  position: relative; }

.blog-loop-standard .sticky h3.line-heading {
  padding-left: 46px; }

.player-card .ribbon-container {
  left: 0;
  top: 6px;
  text-align: center;
  display: block;
  position: absolute; }

.ribbon-container {
  left: 0;
  top: 6px;
  text-align: center;
  display: block;
  position: absolute; }

.single-player-wrap .ribbon-container {
  left: 20px; }

.pro.single-player-wrap .ribbon-container {
  left: 20px;
  right: auto;
  display: block; }

.ribbon-pro {
  padding: 12px;
  width: 6px;
  height: 1px;
  text-align: center;
  position: relative;
  z-index: 20; }

.ribbon-pro .content {
  left: 0;
  height: 28px;
  text-align: center;
  right: 0;
  bottom: 6px;
  color: white;
  line-height: 30px;
  font-size: 11px;
  font-weight: bold;
  border-right-color: rgba(0, 0, 0, 0.1);
  border-left-color: rgba(0, 0, 0, 0.1);
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 20;
  background-color: #ed7721; }

.ribbon-pro .back-sh {
  left: 3px;
  height: 6px;
  right: 3px;
  bottom: 6px;
  position: absolute;
  z-index: 1; }

.ribbon-pro .back-sh-2 {
  left: 0;
  top: -19px;
  height: 24px;
  right: 0;
  position: absolute;
  z-index: 1; }

.ribbon-pro::before {
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 10;
  content: "";
  transform: skew(0deg, -36deg);
  background-color: #ed7721;
  -webkit-transform: skew(0deg, -36deg);
  -moz-transform: skew(0deg, -36deg);
  -o-transform: skew(0deg, -36deg); }

.ribbon-pro::after {
  top: 0;
  width: 50%;
  height: 100%;
  right: 0;
  position: absolute;
  z-index: 10;
  content: "";
  transform: skew(0deg, 36deg);
  background-color: #ed7721;
  -webkit-transform: skew(0deg, 36deg);
  -moz-transform: skew(0deg, 36deg);
  -o-transform: skew(0deg, 36deg); }

.page-heading-wrap h2 {
  -ms-word-wrap: break-word; }

p {
  -ms-word-wrap: break-word; }

.blog-loop-standard h3.line-heading {
  -ms-word-wrap: break-word; }

.prime-nav li.page_item ul.children {
  margin: 0;
  padding: 0;
  top: 56px;
  border-top-color: #ed7721;
  border-top-width: 4px;
  border-top-style: solid;
  display: none;
  list-style-type: none;
  position: absolute;
  z-index: 210;
  min-width: 200px;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06);
  background-color: #f9f9f9;
  -webkit-box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), -1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06);
  text-wrap: none; }

.prime-nav li.page_item:hover ul.children {
  display: block; }

.prime-nav li.page_item {
  position: relative; }

.prime-nav li.page_item:hover ul.children li {
  display: block;
  min-height: 35px;
  text-wrap: none; }

.prime-nav li.page_item:hover ul.children li a {
  padding: 7px 20px 8px;
  border-bottom-color: #ebebeb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: relative;
  text-wrap: none; }

.prime-nav li.page_item:hover ul.children li a > i {
  top: 13px;
  right: 10px;
  font-size: 8px;
  position: absolute;
  opacity: 0.5; }

.prime-nav li.page_item:hover ul.children li a:hover > i {
  opacity: 1; }

.prime-nav li.page_item:hover ul.children li a:hover {
  color: #ed7721;
  background-color: white; }

.prime-nav li > ul.children li.current_page_item > a {
  color: #ed7721;
  background-color: white; }

.prime-nav li.page_item:hover ul.children li:last-child a {
  border-bottom-color: currentColor;
  border-bottom-width: medium;
  border-bottom-style: none; }

.prime-nav > li.page_item > ul.children > li.page_item ul.children {
  left: 100%;
  top: -4px;
  display: none; }

.prime-nav > li.page_item > ul.children > li.page_item:hover ul.children {
  display: block; }

.wp-page-links {
  width: 100%;
  line-height: 30px;
  margin-bottom: 45px;
  float: left;
  display: block; }

.wp-page-links > a > span {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  margin-right: 1px;
  float: left;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.wp-page-links > span {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  margin-right: 1px;
  float: left;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.wp-page-links > span {
  color: white;
  background-color: #ed7721; }

.nova-rev-extend-vl-dark {
  padding: 10px 15px;
  color: white;
  text-transform: uppercase;
  line-height: 40px;
  font-family: "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: 700;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.nova-rev-extend-l {
  color: rgba(0, 0, 0, 0.74);
  text-transform: uppercase;
  line-height: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  display: inline-block; }

.nova-rev-extend-l-light {
  color: white;
  text-transform: uppercase;
  line-height: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  display: inline-block; }

.nova-rev-extend-button.roki-button-link {
  color: black !important;
  box-shadow: inset 0 0 0 1px black;
  background-color: transparent !important;
  -webkit-box-shadow: inset 0 0 0 1px black;
  -moz-box-shadow: inset 0 0 0 1px black; }

.nova-rev-extend-button.roki-button-link span {
  color: black !important;
  background-color: transparent !important; }

.nova-rev-extend-button.roki-button-link::before {
  box-shadow: inset 1px 0 0 0 black;
  background-color: transparent !important;
  -webkit-box-shadow: inset 1px 0 0 0 black;
  -moz-box-shadow: inset 1px 0 0 0 black; }

.nova-rev-extend-button.roki-button-link:hover {
  color: white !important; }

.nova-rev-extend-button-light.roki-button-link {
  color: white !important;
  box-shadow: inset 0 0 0 1px #fff;
  background-color: transparent !important;
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  -moz-box-shadow: inset 0 0 0 1px #fff; }

.nova-rev-extend-button-light.roki-button-link:hover {
  color: black !important;
  box-shadow: inset 0 0 0 1px #fff;
  background-color: white !important;
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  -moz-box-shadow: inset 0 0 0 1px #fff; }

.nova-rev-extend-button-light.roki-button-link:hover span {
  color: black !important;
  background-color: transparent !important; }

.nova-rev-extend-button-light.roki-button-link::before {
  box-shadow: inset 1px 0 0 0 #fff;
  background-color: transparent !important;
  -webkit-box-shadow: inset 1px 0 0 0 #fff;
  -moz-box-shadow: inset 1px 0 0 0 #fff; }

.nova-rev-extend-button-light.roki-button-link:hover::before {
  box-shadow: inset 1px 0 0 0 #fff;
  background-color: transparent !important;
  -webkit-box-shadow: inset 1px 0 0 0 #fff;
  -moz-box-shadow: inset 1px 0 0 0 #fff; }

.roki-rev-slide-extra-text-light {
  width: 300px;
  color: white; }

.roki-rev-slide-extra-text-extend {
  width: 300px; }

.nova-rev-plus-span {
  width: 30px;
  height: 30px;
  color: white;
  line-height: 31px;
  font-size: 10px;
  display: inline-block; }

.nova-rev-heading-extend {
  color: rgba(0, 0, 0, 0.74);
  text-transform: uppercase;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block; }

.woocommerce .row h2 {
  text-transform: uppercase;
  font-size: 18px; }

.woocommerce-page .row h2 {
  text-transform: uppercase;
  font-size: 18px; }

.woocommerce .row .products .product a:hover img {
  position: relative;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row .products .product a img {
  position: relative;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row .woocommerce-result-count {
  margin: 0;
  padding: 7px 30px 0 0;
  line-height: 20px;
  display: inline-block; }

.woocommerce .row .woocommerce-ordering {
  margin: 0 0 30px 20px; }

.woocommerce-ordering-div {
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08); }

.woocommerce .row .woocommerce-ordering select {
  background-position: 0% 0%;
  padding: 0 0 0 12px;
  border-radius: 0;
  border: currentColor !important;
  border-image: none !important;
  width: 200px;
  height: 35px;
  line-height: 36px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  z-index: 2;
  cursor: pointer;
  box-shadow: none;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: transparent !important;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.woocommerce-ordering-div::before {
  top: 0;
  width: 35px;
  right: 0;
  bottom: 0;
  border-left-color: rgba(0, 0, 0, 0.06);
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  z-index: 1;
  content: "";
  background-color: rgba(0, 0, 0, 0.02); }

.woocommerce-ordering-div::after {
  top: 16px;
  width: 0;
  height: 0;
  right: 14px;
  border-top-color: black;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-width: 4px;
  border-right-width: 4px;
  border-left-width: 4px;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  position: absolute;
  z-index: 1;
  content: ""; }

.woocommerce .row .products ul {
  list-style: none;
  margin: 0;
  padding: 0;
  clear: both; }

.woocommerce .row .products {
  list-style: none;
  margin: 0;
  padding: 0;
  clear: both; }

.woocommerce .row .products .product {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce .row .products .product a img {
  margin-bottom: 12px; }

.woocommerce .row .products .product h3 {
  margin: 10px 20px 0;
  padding: 0;
  line-height: 20px;
  font-size: 13px;
  font-weight: 300; }

.woocommerce .row .products .star-rating {
  margin: 12px 20px 0;
  display: none; }

.woocommerce .row .products .product .price {
  margin: 10px 20px 0; }

.woocommerce .row .products .product .price {
  color: black;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 16px; }

.woocommerce .row .product .price {
  color: black;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 16px; }

.woocommerce .row #content .product .price {
  color: black;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 16px; }

.woocommerce .row .product p.price {
  color: black;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 16px; }

.woocommerce .row #content .product p.price {
  color: black;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 16px; }

.woocommerce .row .product .price {
  font-size: 18px; }

.woocommerce .row #content .product .price {
  font-size: 18px; }

.woocommerce .row .product p.price {
  font-size: 18px; }

.woocommerce .row #content .product p.price {
  font-size: 18px; }

.woocommerce .row .products .product .price del {
  color: rgba(0, 0, 0, 0.3);
  font-size: 11px; }

.woocommerce .row .row .products .product .price ins {
  text-decoration: none; }

.woocommerce .row .product .price ins {
  text-decoration: none; }

.woocommerce .row #content .product .price ins {
  text-decoration: none; }

.woocommerce .row .product .price ins {
  text-decoration: none; }

.woocommerce .row #content .product .price ins {
  text-decoration: none; }

.woocommerce .row a.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page a.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row button.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page button.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row input.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page input.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row #respond input#submit {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #respond input#submit {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row #content input.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #content input.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce a.alt.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page a.alt.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce button.alt.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page button.alt.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce input.alt.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page input.alt.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce #respond input.alt#submit {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #respond input.alt#submit {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce #content input.alt.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #content input.alt.button {
  background: #ed7721;
  padding: 0 15px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 35px;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row a.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page a.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce .row button.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page button.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce .row input.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page input.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce .row #respond input#submit:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page #respond input#submit:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce .row #content input.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page #content input.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce a.alt.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page a.alt.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce button.alt.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page button.alt.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce input.alt.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page input.alt.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce #respond input.alt#submit:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page #respond input.alt#submit:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce #content input.alt.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce-page #content input.alt.button:hover {
  background-position: 0% 0%;
  color: white !important;
  background-image: none;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0.74) !important; }

.woocommerce a.button:active {
  top: 0; }

.woocommerce-page a.button:active {
  top: 0; }

.woocommerce button.button:active {
  top: 0; }

.woocommerce-page button.button:active {
  top: 0; }

.woocommerce input.button:active {
  top: 0; }

.woocommerce-page input.button:active {
  top: 0; }

.woocommerce #respond input#submit:active {
  top: 0; }

.woocommerce-page #respond input#submit:active {
  top: 0; }

.woocommerce #content input.button:active {
  top: 0; }

.woocommerce-page #content input.button:active {
  top: 0; }

.woocommerce .row a.added_to_cart {
  padding: 0 15px;
  top: 0;
  height: 35px;
  right: 0;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  z-index: 40;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page a.added_to_cart {
  padding: 0 15px;
  top: 0;
  height: 35px;
  right: 0;
  color: white !important;
  line-height: 35px;
  font-weight: normal;
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  z-index: 40;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row a.added_to_cart:hover {
  background-color: #ed7721; }

.woocommerce-page a.added_to_cart:hover {
  background-color: #ed7721; }

.woocommerce .row .products .product .onsale {
  z-index: 1; }

.woocommerce-page .products .product .onsale {
  z-index: 1; }

.woocommerce .row nav.woocommerce-pagination {
  text-align: left; }

.woocommerce-page nav.woocommerce-pagination {
  text-align: left; }

.woocommerce .row #content nav.woocommerce-pagination {
  text-align: left; }

.woocommerce-page #content nav.woocommerce-pagination {
  text-align: left; }

.woocommerce .row nav.woocommerce-pagination ul {
  margin: 0 0 45px;
  border: currentColor;
  border-image: none;
  width: 100%;
  height: 31px;
  float: left;
  display: block; }

.woocommerce-page nav.woocommerce-pagination ul {
  margin: 0 0 45px;
  border: currentColor;
  border-image: none;
  width: 100%;
  height: 31px;
  float: left;
  display: block; }

.woocommerce .row #content nav.woocommerce-pagination ul {
  margin: 0 0 45px;
  border: currentColor;
  border-image: none;
  width: 100%;
  height: 31px;
  float: left;
  display: block; }

.woocommerce-page #content nav.woocommerce-pagination ul {
  margin: 0 0 45px;
  border: currentColor;
  border-image: none;
  width: 100%;
  height: 31px;
  float: left;
  display: block; }

.woocommerce .row nav.woocommerce-pagination ul li {
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin-right: 1px;
  border-right-color: currentColor;
  border-right-width: medium;
  border-right-style: none;
  float: left;
  display: block; }

.woocommerce-page nav.woocommerce-pagination ul li {
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin-right: 1px;
  border-right-color: currentColor;
  border-right-width: medium;
  border-right-style: none;
  float: left;
  display: block; }

.woocommerce .row #content nav.woocommerce-pagination ul li {
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin-right: 1px;
  border-right-color: currentColor;
  border-right-width: medium;
  border-right-style: none;
  float: left;
  display: block; }

.woocommerce-page #content nav.woocommerce-pagination ul li {
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin-right: 1px;
  border-right-color: currentColor;
  border-right-width: medium;
  border-right-style: none;
  float: left;
  display: block; }

.woocommerce .row nav.woocommerce-pagination ul li a {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page nav.woocommerce-pagination ul li a {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row #content nav.woocommerce-pagination ul li a {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #content nav.woocommerce-pagination ul li a {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row nav.woocommerce-pagination ul li span {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page nav.woocommerce-pagination ul li span {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row #content nav.woocommerce-pagination ul li span {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #content nav.woocommerce-pagination ul li span {
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  display: block;
  min-width: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row nav.woocommerce-pagination ul li span.current {
  color: white;
  background-color: #ed7721; }

.woocommerce-page nav.woocommerce-pagination ul li span.current {
  color: white;
  background-color: #ed7721; }

.woocommerce .row #content nav.woocommerce-pagination ul li span.current {
  color: white;
  background-color: #ed7721; }

.woocommerce-page #content nav.woocommerce-pagination ul li span.current {
  color: white;
  background-color: #ed7721; }

.woocommerce .row nav.woocommerce-pagination ul li a:hover {
  color: white;
  background-color: #ed7721; }

.woocommerce-page nav.woocommerce-pagination ul li a:hover {
  color: white;
  background-color: #ed7721; }

.woocommerce .row #content nav.woocommerce-pagination ul li a:hover {
  color: white;
  background-color: #ed7721; }

.woocommerce-page #content nav.woocommerce-pagination ul li a:hover {
  color: white;
  background-color: #ed7721; }

.woocommerce .row nav.woocommerce-pagination ul li a:focus {
  color: white;
  background-color: #ed7721; }

.woocommerce-page nav.woocommerce-pagination ul li a:focus {
  color: white;
  background-color: #ed7721; }

.woocommerce .row #content nav.woocommerce-pagination ul li a:focus {
  color: white;
  background-color: #ed7721; }

.woocommerce-page #content nav.woocommerce-pagination ul li a:focus {
  color: white;
  background-color: #ed7721; }

.woocommerce .row .image-wrap::before {
  left: 20px;
  width: 0;
  height: 0;
  bottom: 0;
  position: absolute;
  z-index: 5;
  content: ""; }

.woocommerce .row .image-wrap::after {
  left: 20px;
  width: 0;
  height: 0;
  bottom: 0;
  position: absolute;
  z-index: 5;
  content: ""; }

.woocommerce .row .image-wrap::before {
  bottom: 0;
  margin-left: -5px;
  border-right-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.04);
  border-left-color: transparent;
  border-right-width: 8px;
  border-bottom-width: 8px;
  border-left-width: 8px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  z-index: 4;
  content: ""; }

.woocommerce .row .image-wrap::after {
  margin-left: -3px;
  border-right-color: transparent;
  border-bottom-color: #f9f9f9;
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  content: ""; }

.woocommerce .row .ribbon-pro .content {
  border-right-color: currentColor;
  border-left-color: currentColor;
  border-right-width: medium;
  border-left-width: medium;
  border-right-style: none;
  border-left-style: none; }

.woocommerce .row .ribbon-container {
  left: 10px;
  top: 7px; }

.woocommerce a.added.button::before {
  display: none;
  background-image: none; }

.woocommerce-page a.added.button::before {
  display: none;
  background-image: none; }

.woocommerce a.loading.button::before {
  display: none;
  background-image: none; }

.woocommerce-page a.loading.button::before {
  display: none;
  background-image: none; }

.woocommerce a.loading.button::before {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.6); }

.woocommerce-page a.loading.button::before {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.6); }

.woocommerce.single-product .row .product {
  margin-top: 45px; }

.woocommerce.single-product .row #content .product {
  margin-top: 45px; }

.woocommerce .row .product .images img {
  transition: none;
  box-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row #content .product .images img {
  transition: none;
  box-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row .product .images {
  margin-bottom: 30px; }

.woocommerce .row #content .product .images {
  margin-bottom: 30px; }

.woocommerce .row .product .cart {
  margin: 30px 0; }

.woocommerce .row #content .product .cart {
  margin: 30px 0; }

.woocommerce .row .product .cart .quantity {
  margin: 1px 103px 0 0;
  height: 35px; }

.woocommerce .row #content .product .cart .quantity {
  margin: 1px 103px 0 0;
  height: 35px; }

.woocommerce .quantity {
  width: auto;
  overflow: inherit;
  position: relative; }

.woocommerce-page .quantity {
  width: auto;
  overflow: inherit;
  position: relative; }

.woocommerce #content .quantity {
  width: auto;
  overflow: inherit;
  position: relative; }

.woocommerce-page #content .quantity {
  width: auto;
  overflow: inherit;
  position: relative; }

.woocommerce .row input.quantity .qty {
  padding: 7px 10px 6px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row #content input.quantity .qty {
  padding: 7px 10px 6px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row .quantity .minus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row #content .quantity .minus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row .quantity .plus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row #content .quantity .plus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .row .quantity .minus:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74); }

.woocommerce .row #content .quantity .minus:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74); }

.woocommerce .row .quantity .plus:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74); }

.woocommerce .row #content .quantity .plus:hover {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.74); }

.woocommerce .row .quantity .minus {
  top: -1px;
  right: -73px; }

.woocommerce .row #content .quantity .minus {
  top: -1px;
  right: -73px; }

.woocommerce .row .quantity .plus {
  top: -1px;
  right: -37px; }

.woocommerce .row #content .quantity .plus {
  top: -1px;
  right: -37px; }

.woocommerce .row .product .price {
  margin: 0 0 20px; }

.woocommerce .row .product .price del {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px; }

.woocommerce .row #content .product .price del {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px; }

.woocommerce .row .product .price del {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px; }

.woocommerce .row #content .product .price del {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px; }

.woocommerce .row .product .images .thumbnails {
  padding-top: 30px; }

.woocommerce .row #content .product .images .thumbnails {
  padding-top: 30px; }

.woocommerce .row .product .woocommerce-tabs ul.tabs {
  padding: 0;
  margin-bottom: -1px;
  background-color: white; }

.woocommerce .row #content .product .woocommerce-tabs ul.tabs {
  padding: 0;
  margin-bottom: -1px;
  background-color: white; }

.woocommerce .row .product .woocommerce-tabs ul.tabs::before {
  margin-bottom: 0;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.woocommerce .row #content .product .woocommerce-tabs ul.tabs::before {
  margin-bottom: 0;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.woocommerce .row .product .woocommerce-tabs .panel {
  margin: 0 0 45px;
  padding: 20px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: #f9f9f9;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce .row #content .product .woocommerce-tabs .panel {
  margin: 0 0 45px;
  padding: 20px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: #f9f9f9;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce .row .product .woocommerce-tabs .panel h2 {
  margin: 0 0 20px;
  text-transform: uppercase;
  line-height: 24px;
  font-size: 18px; }

.woocommerce .row #content .product .woocommerce-tabs .panel h2 {
  margin: 0 0 20px;
  text-transform: uppercase;
  line-height: 24px;
  font-size: 18px; }

.woocommerce .row .product .woocommerce-tabs .panel p:last-child {
  margin-bottom: 0; }

.woocommerce .row #content .product .woocommerce-tabs .panel p:last-child {
  margin-bottom: 0; }

.woocommerce .row .product .woocommerce-tabs ul.tabs li.active {
  border-bottom-color: #f9f9f9;
  z-index: 2;
  background-color: #f9f9f9; }

.woocommerce .row #content .product .woocommerce-tabs ul.tabs li.active {
  border-bottom-color: #f9f9f9;
  z-index: 2;
  background-color: #f9f9f9; }

.woocommerce .row .product .woocommerce-tabs ul.tabs li {
  background: none;
  margin: 0 10px 0 0;
  padding: 0;
  border: currentColor;
  border-image: none;
  display: inline-block;
  position: relative;
  z-index: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0; }

.woocommerce .row #content .product .woocommerce-tabs ul.tabs li {
  background: none;
  margin: 0 10px 0 0;
  padding: 0;
  border: currentColor;
  border-image: none;
  display: inline-block;
  position: relative;
  z-index: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.06), inset -1px 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0; }

.woocommerce .row .product .woocommerce-tabs ul.tabs li a {
  padding: 0 20px;
  height: 45px;
  color: #5e5e5e;
  line-height: 45px;
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8); }

.woocommerce .row #content .product .woocommerce-tabs ul.tabs li a {
  padding: 0 20px;
  height: 45px;
  color: #5e5e5e;
  line-height: 45px;
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8); }

.woocommerce .row .product .woocommerce-tabs .tabs li::before {
  border: currentColor !important;
  border-image: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row #content .product .woocommerce-tabs .tabs li::before {
  border: currentColor !important;
  border-image: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row .product .woocommerce-tabs .tabs li::after {
  border: currentColor !important;
  border-image: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row #content .product .woocommerce-tabs .tabs li::after {
  border: currentColor !important;
  border-image: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row .product .woocommerce-tabs .tabs li.active::after {
  border: currentColor !important;
  border-image: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row #content .product .woocommerce-tabs .tabs li.active::after {
  border: currentColor !important;
  border-image: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row .product .woocommerce-tabs .tabs li.active::before {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce .row #content .product .woocommerce-tabs .tabs li.active::before {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  background: #f7f6f7;
  margin: 0;
  padding: 0;
  border: currentColor;
  border-image: none;
  left: 0;
  top: 0;
  width: 35px;
  height: auto;
  line-height: 1;
  float: left;
  position: absolute; }

.woocommerce-page #reviews #comments ol.commentlist li img.avatar {
  background: #f7f6f7;
  margin: 0;
  padding: 0;
  border: currentColor;
  border-image: none;
  left: 0;
  top: 0;
  width: 35px;
  height: auto;
  line-height: 1;
  float: left;
  position: absolute; }

.woocommerce #reviews #comments ol.commentlist li {
  background: 0;
  margin: 0 0 30px;
  padding: 0 0 30px;
  border: 0 currentColor;
  border-image: none;
  position: relative; }

.woocommerce-page #reviews #comments ol.commentlist li {
  background: 0;
  margin: 0 0 30px;
  padding: 0 0 30px;
  border: 0 currentColor;
  border-image: none;
  position: relative; }

.woocommerce #reviews #comments ol.commentlist li .description p:last-child {
  margin-bottom: 0; }

.woocommerce-page #reviews #comments ol.commentlist li .description p:last-child {
  margin-bottom: 0; }

.woocommerce #reviews #comments ol.commentlist li .comment-text {
  border-width: medium medium medium 4px;
  border-style: none none none solid;
  border-color: currentColor currentColor currentColor #ed7721;
  margin: 0 0 0 60px;
  padding: 0 0 0 20px;
  border-radius: 0;
  border-image: none;
  position: relative;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce-page #reviews #comments ol.commentlist li .comment-text {
  border-width: medium medium medium 4px;
  border-style: none none none solid;
  border-color: currentColor currentColor currentColor #ed7721;
  margin: 0 0 0 60px;
  padding: 0 0 0 20px;
  border-radius: 0;
  border-image: none;
  position: relative;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce #reviews #comments ol.commentlist li .comment-text::after {
  left: -8px;
  top: 11px;
  width: 0;
  height: 0;
  border-top-color: transparent;
  border-right-color: #ed7721;
  border-bottom-color: transparent;
  border-top-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  position: absolute;
  z-index: 2;
  content: ""; }

.woocommerce #reviews #comments ol.commentlist li .comment-text::after {
  left: -8px;
  top: 11px;
  width: 0;
  height: 0;
  border-top-color: transparent;
  border-right-color: #ed7721;
  border-bottom-color: transparent;
  border-top-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  position: absolute;
  z-index: 2;
  content: ""; }

.woocommerce #reviews #comments ol.commentlist li .meta {
  color: black;
  font-size: 13px; }

.woocommerce-page #reviews #comments ol.commentlist li .meta {
  color: black;
  font-size: 13px; }

.woocommerce #reviews #comments ol.commentlist li .meta time {
  color: rgba(0, 0, 0, 0.5); }

.woocommerce-page #reviews #comments ol.commentlist li .meta time {
  color: rgba(0, 0, 0, 0.5); }

.woocommerce #reviews #comments ol.commentlist li .meta strong {
  text-transform: uppercase; }

.woocommerce-page #reviews #comments ol.commentlist li .meta strong {
  text-transform: uppercase; }

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
  font-size: 13px; }

.woocommerce-page #reviews #comments ol.commentlist li .comment-text p.meta {
  font-size: 13px; }

div.pp_woocommerce .pp_content_container {
  padding: 30px 0; }

.woocommerce .row .product p.stock {
  color: black;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold; }

.woocommerce .row #content .product p.stock {
  color: black;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold; }

.page-heading-wrap h2 > span ins {
  font-weight: bold;
  text-decoration: none;
  margin-left: 10px; }

.product_meta .posted_in a {
  margin: 0 0 1px;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  font-size: 13px !important;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.product_meta .posted_in a:hover {
  color: white;
  background-color: #ed7721; }

.product_meta .posted_in a:first-child {
  margin-left: 10px; }

.woocommerce .row .type-product .image-wrap-single-product.image-wrap::before {
  left: auto;
  top: 50%;
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
  z-index: 5;
  content: ""; }

.woocommerce .row .type-product .image-wrap-single-product.image-wrap::after {
  left: auto;
  top: 50%;
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
  z-index: 5;
  content: ""; }

.woocommerce .row .type-product .image-wrap-single-product.image-wrap::before {
  bottom: 0;
  margin-top: -6px;
  border-top-color: transparent;
  border-right-color: rgba(0, 0, 0, 0.04);
  border-bottom-color: transparent;
  border-top-width: 8px;
  border-right-width: 8px;
  border-bottom-width: 8px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  z-index: 4;
  content: ""; }

.woocommerce .row .type-product .image-wrap-single-product.image-wrap::after {
  right: 0;
  margin-top: -4px;
  border-top-color: transparent;
  border-right-color: white;
  border-bottom-color: transparent;
  border-top-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  content: ""; }

.woocommerce .row .type-product .thumbnails .image-wrap::before {
  display: none; }

.woocommerce .row .type-product .thumbnails .image-wrap::after {
  display: none; }

.woocommerce .related ul.products li.product {
  width: 23.07%; }

.woocommerce-page .related ul.products li.product {
  width: 23.07%; }

.woocommerce .products.upsells ul.products li.product {
  width: 23.07%; }

.woocommerce-page .products.upsells ul.products li.product {
  width: 23.07%; }

.woocommerce .related ul li.product {
  width: 23.07%; }

.woocommerce-page .related ul li.product {
  width: 23.07%; }

.woocommerce .products.upsells ul li.product {
  width: 23.07%; }

.woocommerce-page .products.upsells ul li.product {
  width: 23.07%; }

.woocommerce.single-product .upsells h2 {
  margin: 0;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce.single-product .related h2 {
  margin: 0;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce.single-product .upsells .products .product {
  margin-top: 30px; }

.woocommerce.single-product .related .products .product {
  margin-top: 30px; }

.woocommerce div.product div.images div.row div.thumbnails a {
  width: auto;
  margin-right: 0;
  float: none;
  display: block; }

.woocommerce textarea {
  padding: 12px 3% 11px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 94%;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08); }

.woocommerce textarea:focus {
  padding: 12px 3% 11px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 94%;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08); }

#pp_full_res .pp_inline p label {
  margin: 0 0 15px; }

#pp_full_res .pp_inline p.stars {
  margin-bottom: 30px; }

.woocommerce form .form-row input.input-text {
  padding: 12px 10px 11px !important;
  border-radius: 0 !important;
  border: currentColor !important;
  border-image: none !important;
  height: 43px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  font-style: italic !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08); }

.woocommerce-page form .form-row input.input-text {
  padding: 12px 10px 11px !important;
  border-radius: 0 !important;
  border: currentColor !important;
  border-image: none !important;
  height: 43px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  font-style: italic !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08); }

.woocommerce table.cart td.actions .coupon .input-text {
  padding: 12px 10px 11px !important;
  border-radius: 0 !important;
  border: currentColor !important;
  border-image: none !important;
  height: 43px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  font-style: italic !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08); }

.woocommerce-page table.cart td.actions .coupon .input-text {
  padding: 12px 10px 11px !important;
  border-radius: 0 !important;
  border: currentColor !important;
  border-image: none !important;
  height: 43px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  font-style: italic !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08); }

.woocommerce #content table.cart td.actions .coupon .input-text {
  padding: 12px 10px 11px !important;
  border-radius: 0 !important;
  border: currentColor !important;
  border-image: none !important;
  height: 43px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  font-style: italic !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08); }

.woocommerce-page #content table.cart td.actions .coupon .input-text {
  padding: 12px 10px 11px !important;
  border-radius: 0 !important;
  border: currentColor !important;
  border-image: none !important;
  height: 43px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  font-style: italic !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08); }

.woocommerce-page label {
  font-size: 13px; }

.woocommerce-page.woocommerce-checkout label {
  margin-top: 20px; }

.woocommerce.woocommerce-checkout .row div.col-1 :first-child.form-row-wide label {
  margin-top: 0; }

.woocommerce form .form-row {
  padding-right: 0;
  padding-left: 0; }

.woocommerce-page form .form-row {
  padding-right: 0;
  padding-left: 0; }

.woocommerce-page.woocommerce-checkout h3 {
  margin: 0;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce .checkout #shiptobilling label {
  padding-left: 0;
  font-size: 13px; }

.woocommerce-page .checkout #shiptobilling label {
  padding-left: 0;
  font-size: 13px; }

.woocommerce-message {
  background: #f9f9f9;
  list-style: none;
  margin: 0 0 30px;
  padding: 30px 30px 30px 70px;
  border-radius: 0;
  width: auto;
  color: black;
  border-top-color: currentColor;
  border-top-width: 0;
  border-top-style: none;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-error {
  background: #f9f9f9;
  list-style: none;
  margin: 0 0 30px;
  padding: 30px 30px 30px 70px;
  border-radius: 0;
  width: auto;
  color: black;
  border-top-color: currentColor;
  border-top-width: 0;
  border-top-style: none;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-info {
  background: #f9f9f9;
  list-style: none;
  margin: 0 0 30px;
  padding: 30px 30px 30px 70px;
  border-radius: 0;
  width: auto;
  color: black;
  border-top-color: currentColor;
  border-top-width: 0;
  border-top-style: none;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.single-product .woocommerce-message {
  margin-top: 45px; }

.single-product .woocommerce-error {
  margin-top: 45px; }

.single-product .woocommerce-info {
  margin-top: 45px; }

.woocommerce-message::before {
  left: 25px;
  top: 25px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: white;
  line-height: 31px;
  padding-top: 0;
  font-family: sans-serif;
  font-size: 1em;
  font-style: italic;
  display: block;
  position: absolute;
  content: "i";
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  background-color: #ed7721;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-bottomright: 0; }

.woocommerce-error::before {
  left: 25px;
  top: 25px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: white;
  line-height: 31px;
  padding-top: 0;
  font-family: sans-serif;
  font-size: 1em;
  font-style: italic;
  display: block;
  position: absolute;
  content: "i";
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  background-color: #ed7721;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-bottomright: 0; }

.woocommerce-info::before {
  left: 25px;
  top: 25px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: white;
  line-height: 31px;
  padding-top: 0;
  font-family: sans-serif;
  font-size: 1em;
  font-style: italic;
  display: block;
  position: absolute;
  content: "i";
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  background-color: #ed7721;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-bottomright: 0; }

.woocommerce form.login {
  margin: 0 0 30px;
  padding: 30px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  text-align: left;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-page form.login {
  margin: 0 0 30px;
  padding: 30px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  text-align: left;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce form.checkout_coupon {
  margin: 0 0 30px;
  padding: 30px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  text-align: left;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-page form.checkout_coupon {
  margin: 0 0 30px;
  padding: 30px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  text-align: left;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce form.register {
  margin: 0 0 30px;
  padding: 30px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  text-align: left;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-page form.register {
  margin: 0 0 30px;
  padding: 30px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  text-align: left;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-page .checkout_coupon input.button {
  padding: 0 20px;
  height: 45px;
  line-height: 45px;
  margin-top: -1px; }

h3#order_review_heading {
  margin: 40px 0 20px; }

.woocommerce table.shop_table {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-image: none;
  border-collapse: separate;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce-page table.shop_table {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-image: none;
  border-collapse: separate;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce table.shop_table td {
  border-top-color: rgba(0, 0, 0, 0.08);
  border-top-width: 1px;
  border-top-style: solid; }

.woocommerce-page table.shop_table td {
  border-top-color: rgba(0, 0, 0, 0.08);
  border-top-width: 1px;
  border-top-style: solid; }

.woocommerce #payment {
  border-radius: 0;
  border: currentColor;
  border-image: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: #f9f9f9;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-page #payment {
  border-radius: 0;
  border: currentColor;
  border-image: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: #f9f9f9;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce #payment ul.payment_methods li {
  position: relative; }

.woocommerce-page #payment ul.payment_methods li {
  position: relative; }

.woocommerce #payment .input-radio {
  left: 0;
  top: 4px;
  float: left;
  position: absolute; }

.woocommerce-page #payment .input-radio {
  left: 0;
  top: 4px;
  float: left;
  position: absolute; }

.woocommerce #payment label {
  padding-left: 20px; }

.woocommerce-page #payment label {
  padding-left: 20px; }

.woocommerce #payment div.payment_box {
  background: white;
  margin: 1em 0;
  padding: 20px;
  border-radius: 0;
  width: 96%;
  color: black;
  line-height: 1.5em;
  font-size: 0.92em;
  border-top-color: #ed7721;
  border-top-width: 4px;
  border-top-style: solid;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #payment div.payment_box {
  background: white;
  margin: 1em 0;
  padding: 20px;
  border-radius: 0;
  width: 96%;
  color: black;
  line-height: 1.5em;
  font-size: 0.92em;
  border-top-color: #ed7721;
  border-top-width: 4px;
  border-top-style: solid;
  position: relative;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce #payment div.payment_box {
  font-size: 13px;
  text-shadow: none; }

.woocommerce-page #payment div.payment_box {
  font-size: 13px;
  text-shadow: none; }

.woocommerce #payment div.payment_box::after {
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #ed7721;
  border-image: none;
  left: -10px; }

.woocommerce-page #payment div.payment_box::after {
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #ed7721;
  border-image: none;
  left: -10px; }

.woocommerce #payment ul.payment_methods {
  border: currentColor;
  border-image: none; }

.woocommerce-page #payment ul.payment_methods {
  border: currentColor;
  border-image: none; }

.woocommerce #payment div.form-row {
  border: currentColor;
  border-image: none; }

.woocommerce-page #payment div.form-row {
  border: currentColor;
  border-image: none; }

.woocommerce .cart-collaterals .cart_totals table {
  float: right; }

.woocommerce-page .cart-collaterals .cart_totals table {
  float: right; }

.woocommerce .cart-collaterals .cart_totals h2 {
  margin: 0 0 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce-page .cart-collaterals .cart_totals h2 {
  margin: 0 0 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce .cart-collaterals .shipping_calculator h2 {
  margin: 0 0 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce-page .cart-collaterals .shipping_calculator h2 {
  margin: 0 0 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce table.cart td.actions .coupon .input-text {
  height: 35px;
  margin-right: 10px; }

.woocommerce-page table.cart td.actions .coupon .input-text {
  height: 35px;
  margin-right: 10px; }

.woocommerce #content table.cart td.actions .coupon .input-text {
  height: 35px;
  margin-right: 10px; }

.woocommerce-page #content table.cart td.actions .coupon .input-text {
  height: 35px;
  margin-right: 10px; }

.woocommerce table.shop_table td {
  padding: 10px;
  border-top-color: rgba(0, 0, 0, 0.08);
  border-top-width: 1px;
  border-top-style: solid; }

.woocommerce-page table.shop_table td {
  padding: 10px;
  border-top-color: rgba(0, 0, 0, 0.08);
  border-top-width: 1px;
  border-top-style: solid; }

.woocommerce .quantity {
  margin: 1px 103px 0 0;
  width: 60px;
  height: 35px; }

.woocommerce-page .quantity {
  margin: 1px 103px 0 0;
  width: 60px;
  height: 35px; }

.woocommerce #content .quantity {
  margin: 1px 103px 0 0;
  width: 60px;
  height: 35px; }

.woocommerce-page #content .quantity {
  margin: 1px 103px 0 0;
  width: 60px;
  height: 35px; }

.woocommerce .row .quantity {
  width: auto;
  overflow: inherit;
  position: relative; }

.woocommerce .row #content .quantity {
  width: auto;
  overflow: inherit;
  position: relative; }

.woocommerce .quantity input.qty {
  padding: 7px 10px 6px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page .quantity input.qty {
  padding: 7px 10px 6px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce #content .quantity input.qty {
  padding: 7px 10px 6px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #content .quantity input.qty {
  padding: 7px 10px 6px;
  border-radius: 0;
  border: currentColor;
  border-image: none;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-image: none;
  background-color: rgba(0, 0, 0, 0.02);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .quantity .minus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page .quantity .minus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce #content .quantity .minus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #content .quantity .minus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .quantity .plus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page .quantity .plus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce #content .quantity .plus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce-page #content .quantity .plus {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border: currentColor;
  border-image: none;
  width: 35px;
  height: 35px;
  color: black !important;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  z-index: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  text-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

.woocommerce .quantity .minus:hover {
  background: rgba(0, 0, 0, 0.74);
  color: white !important; }

.woocommerce-page .quantity .minus:hover {
  background: rgba(0, 0, 0, 0.74);
  color: white !important; }

.woocommerce #content .quantity .minus:hover {
  background: rgba(0, 0, 0, 0.74);
  color: white !important; }

.woocommerce-page #content .quantity .minus:hover {
  background: rgba(0, 0, 0, 0.74);
  color: white !important; }

.woocommerce .quantity .plus:hover {
  background: rgba(0, 0, 0, 0.74);
  color: white !important; }

.woocommerce-page .quantity .plus:hover {
  background: rgba(0, 0, 0, 0.74);
  color: white !important; }

.woocommerce #content .quantity .plus:hover {
  background: rgba(0, 0, 0, 0.74);
  color: white !important; }

.woocommerce-page #content .quantity .plus:hover {
  background: rgba(0, 0, 0, 0.74);
  color: white !important; }

.woocommerce .quantity .minus {
  top: -1px;
  right: -73px; }

.woocommerce-page .quantity .minus {
  top: -1px;
  right: -73px; }

.woocommerce #content .quantity .minus {
  top: -1px;
  right: -73px; }

.woocommerce-page #content .quantity .minus {
  top: -1px;
  right: -73px; }

.woocommerce .quantity .plus {
  top: -1px;
  right: -37px; }

.woocommerce-page .quantity .plus {
  top: -1px;
  right: -37px; }

.woocommerce #content .quantity .plus {
  top: -1px;
  right: -37px; }

.woocommerce-page #content .quantity .plus {
  top: -1px;
  right: -37px; }

.woocommerce table.cart a.remove {
  border-radius: 0;
  color: #ed7721;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce-page table.cart a.remove {
  border-radius: 0;
  color: #ed7721;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce #content table.cart a.remove {
  border-radius: 0;
  color: #ed7721;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce-page #content table.cart a.remove {
  border-radius: 0;
  color: #ed7721;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce table.cart a.remove:hover {
  border-radius: 0;
  background-color: #ed7721;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce-page table.cart a.remove:hover {
  border-radius: 0;
  background-color: #ed7721;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce #content table.cart a.remove:hover {
  border-radius: 0;
  background-color: #ed7721;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce-page #content table.cart a.remove:hover {
  border-radius: 0;
  background-color: #ed7721;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.woocommerce-account .main-content h2 {
  margin: 30px 0 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce-account .main-content h3 {
  margin: 30px 0 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 18px; }

.woocommerce-account .main-content .lost_password {
  margin-left: 10px; }

p.myaccount_user {
  padding: 30px;
  display: block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  background-color: #f9f9f9;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-account.woocommerce .col2-set .col-1 {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-account.woocommerce-page .col2-set .col-1 {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-account.woocommerce .col2-set .col-2 {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce-account.woocommerce-page .col2-set .col-2 {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), inset 0 -4px 0 0 rgba(0, 0, 0, 0.03); }

.woocommerce .title {
  margin: 20px 30px; }

.woocommerce-page .title {
  margin: 20px 30px; }

.woocommerce .title h3 {
  margin: 0; }

.woocommerce-page .title h3 {
  margin: 0; }

.woocommerce address {
  padding: 0 30px 10px; }

.woocommerce-page address {
  padding: 0 30px 10px; }

.has-cart.bluebox-info-bar > .bluebox-container {
  position: relative; }

.has-cart.bluebox-info-bar .info-bar-text {
  padding-right: 100px; }

#gumm-woocommerce-header-info-cart {
  top: 0;
  right: 0;
  margin-left: 30px;
  float: right;
  display: inline-block;
  position: absolute; }

#gumm-woocommerce-menu-cart-button {
  float: right; }

#gumm-woocommerce-menu-cart-button > i::after {
  color: rgba(0, 0, 0, 0.3);
  content: "  /"; }

.gumm-woocommerce-cart-contents {
  padding: 0 15px;
  left: -50%;
  top: 27px;
  width: 120px;
  height: 35px;
  text-align: center;
  color: white;
  line-height: 35px;
  margin-right: -60px;
  display: inline-block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.74); }

.gumm-woocommerce-cart-contents::after {
  left: 50%;
  top: -6px;
  width: 0;
  height: 0;
  margin-left: -4px;
  border-right-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.74);
  border-left-color: transparent;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  position: absolute;
  content: ""; }

ul.product-categories li.cat-item {
  margin: 0 1px 3px 0;
  display: inline-block; }

ul.product-categories li.cat-item a {
  margin: 0 0 1px;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  font-size: 13px !important;
  display: inline-block;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06); }

ul.product-categories li.cat-item a:hover {
  color: white;
  background-color: #ed7721; }

.woocommerce ul.cart_list li {
  padding: 0 0 20px;
  margin-bottom: 20px;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.woocommerce-page ul.cart_list li {
  padding: 0 0 20px;
  margin-bottom: 20px;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.woocommerce ul.product_list_widget li {
  padding: 0 0 20px;
  margin-bottom: 20px;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.woocommerce-page ul.product_list_widget li {
  padding: 0 0 20px;
  margin-bottom: 20px;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.woocommerce ul.cart_list li img {
  width: 60px;
  height: auto;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce-page ul.cart_list li img {
  width: 60px;
  height: auto;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce ul.product_list_widget li img {
  width: 60px;
  height: auto;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce-page ul.product_list_widget li img {
  width: 60px;
  height: auto;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.woocommerce ul.cart_list li a {
  text-transform: uppercase;
  font-size: 14px; }

.woocommerce-page ul.cart_list li a {
  text-transform: uppercase;
  font-size: 14px; }

.woocommerce ul.product_list_widget li a {
  text-transform: uppercase;
  font-size: 14px; }

.woocommerce-page ul.product_list_widget li a {
  text-transform: uppercase;
  font-size: 14px; }

.woocommerce ul.cart_list li .star-rating {
  margin: 4px 0; }

.woocommerce-page ul.cart_list li .star-rating {
  margin: 4px 0; }

.woocommerce ul.product_list_widget li .star-rating {
  margin: 4px 0; }

.woocommerce-page ul.product_list_widget li .star-rating {
  margin: 4px 0; }

/* Form */
select,
input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'] {
  border-radius: 0px;
  box-shadow: none;
  border: 1px solid #F0F0F0; }

textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input, select {
  border: 1px solid #ccc; }

#searchform input[type='text'] {
  padding: 12px 5% 11px;
  border-radius: 0;
  border: currentColor !important;
  border-image: none !important;
  width: 90%;
  height: 21px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08); }

#searchform .screen-reader-text {
  display: none; }

#searchform #searchsubmit {
  display: none; }

ins {
  text-decoration: none; }

.woocommerce .ui-slider-horizontal {
  margin: 30px 0;
  height: 4px; }

.woocommerce-page .ui-slider-horizontal {
  margin: 30px 0;
  height: 4px; }

.woocommerce .ui-slider {
  box-shadow: none;
  background-color: #ed7721;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none; }

.woocommerce-page .ui-slider {
  box-shadow: none;
  background-color: #ed7721;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none; }

.woocommerce .ui-slider .ui-state-default.ui-slider-handle {
  border-radius: 100%;
  border: 2px solid white;
  border-image: none;
  width: 16px;
  height: 16px;
  background-image: none;
  background-color: black;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%; }

.woocommerce-page .ui-slider .ui-state-default.ui-slider-handle {
  border-radius: 100%;
  border: 2px solid white;
  border-image: none;
  width: 16px;
  height: 16px;
  background-image: none;
  background-color: black;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%; }

.woocommerce .ui-slider .ui-state-default.ui-slider-handle:hover {
  background-color: #ed7721; }

.woocommerce-page .ui-slider .ui-state-default.ui-slider-handle:hover {
  background-color: #ed7721; }

.woocommerce .ui-slider-horizontal .ui-slider-handle {
  top: -8px; }

.woocommerce-page .ui-slider-horizontal .ui-slider-handle {
  top: -8px; }

.price_slider_amount .price_label {
  line-height: 35px;
  margin-left: 20px;
  float: right; }

input#min_price {
  margin: 0;
  height: 10px;
  opacity: 0; }

input#max_price {
  margin: 0;
  height: 10px;
  opacity: 0; }

.gumm-filterable-items {
  overflow: hidden;
  -webkit-transform: translateZ(0); }

.gumm-filterable-items .gumm-filterable-item {
  box-sizing: border-box; }

.gumm-filterable-items .gutter-sizer {
  box-sizing: border-box; }

.gumm-filterable-items div.inactive.gumm-filterable-item {
  animation: dimonniFadeOut 0.5s forwards;
  -webkit-animation: dimonniFadeOut .5s forwards;
  -moz-animation: dimonniFadeOut .5s forwards;
  -o-animation: dimonniFadeOut .5s forwards; }

.gumm-filterable-items div.active.gumm-filterable-item {
  animation: dimonniFadeIn 0.5s forwards;
  -webkit-animation: dimonniFadeIn .5s forwards;
  -moz-animation: dimonniFadeIn .5s forwards;
  -o-animation: dimonniFadeIn .5s forwards; }

#wpadminbar a.ab-item {
  color: #cccccc !important; }

#wpadminbar > #wp-toolbar span.ab-label {
  color: #cccccc !important; }

#wpadminbar > #wp-toolbar span.noticon {
  color: #cccccc !important; }

#wpadminbar .ab-top-menu > li > .ab-item:focus {
  background: #333333 !important;
  color: #2ea2cc !important; }

.nojq#wpadminbar .quicklinks .ab-top-menu > li > .ab-item:focus {
  background: #333333 !important;
  color: #2ea2cc !important; }

#wpadminbar .ab-top-menu > li:hover > .ab-item {
  background: #333333 !important;
  color: #2ea2cc !important; }

#wpadminbar .ab-top-menu > li.hover > .ab-item {
  background: #333333 !important;
  color: #2ea2cc !important; }

.row::before, .row::after {
  display: table;
  content: "";
  line-height: 0; }

.row::after {
  clear: both; }

.footer-wrap .span6:last-child {
  float: right;
  width: 25%; }

.row [class*="span"]:first-child {
  margin-left: 0; }

[class*="span"] {
  min-height: 1px; }

.row [class*="span"] {
  min-height: 30px; }

.nav-tabs li > a {
  display: block;
  position: relative;
  line-height: 20px;
  padding: 7px 20px 8px 20px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset -4px 0px 0px 0px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset -4px 0px 0px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset -4px 0px 0px 0px rgba(0, 0, 0, 0.04); }

.nav-tabs li > a:hover,
.nav-tabs li.active > a {
  background-color: #fff;
  color: #ed7721;
  /* bluebox option 1 */
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset -4px 0px 0px 0px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset -4px 0px 0px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset -4px 0px 0px 0px rgba(0, 0, 0, 0.04); }

.nav-tabs li.active > a {
  color: #ed7721 !important;
  /* bluebox option 1 */ }

.nav-tabs li.active > a:before {
  content: '';
  position: absolute;
  z-index: 10;
  top: -1px;
  left: 0;
  bottom: 0;
  width: 4px;
  background-color: #ed7721;
  /* bluebox option 1 */ }

.nav-tabs li.active > a:after {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0;
  bottom: 1px;
  width: 4px;
  background-color: #fff; }

.nav-tabs li.active:before {
  content: '';
  z-index: 11;
  height: 0;
  width: 0;
  position: absolute;
  top: 13px;
  left: 4px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #ed7721;
  /* bluebox option 1 */ }

/* HORIZONTAL SEARCH FORM on green backgroun */
.homeForm .titoloForm {
  margin-right: 20px; }

.homeForm {
  width: 100%;
  padding: 20px 0;
  background-color: #B5CA41;
  margin-bottom: -40px;
  visibility: inherit; }

.homeForm h1, .homeForm h3 {
  line-height: 1.1em;
  font-size: 1.4em;
  color: #FFF;
  margin: 0; }

.homeForm .titoloForm {
  float: left; }

.homeForm .ricercaAvanzata {
  margin-top: 10px;
  margin-right: 3%;
  float: right; }

.homeForm .ricercaAvanzata a {
  color: #FFF; }

.homeForm .divCampi {
  width: 40%;
  margin: 6px 0;
  float: left; }

.homeForm .divBott {
  width: 18%;
  margin: 6px 0;
  float: left; }

.homeForm input {
  width: 95%;
  padding: 8px 4px;
  border: 1px solid white;
  border-radius: 0;
  box-shadow: none; }

.homeForm button {
  width: 95%;
  padding: 8px 4px;
  border: 1px solid white;
  border-radius: 0;
  color: #FFF;
  background: none;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

.homeForm .divBott button:hover {
  background-color: #96B80E;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

.clearfix {
  clear: both; }

.homeBrand {
  margin: 0px;
  padding: 14px 0px;
  border: 1px solid #ccc;
  border-image: none;
  width: 100%;
  text-align: center; }

.homeBrand img {
  margin: 0px 8px;
  padding: 0px;
  display: inline; }

.privacy {
  max-height: 35vh;
  overflow: auto;
  padding: 10px;
  border: 1px solid #005e83;
  text-align: justify; }

.privacy-register {
  max-height: 685px;
  overflow: auto;
  padding: 10px;
  border: 1px solid #005e83;
  text-align: justify; }

@media (max-width: 992px) {
  .privacy-register {
    max-height: 35vh; } }

@media (min-width: 992px) {
  .register-text {
    padding-right: 15px; } }

#collapsePrivacy .btn-default:active:hover, #collapsePrivacy .btn-default:active:focus, #collapsePrivacy .btn-default:active.focus, #collapsePrivacy .btn-default.active:hover, #collapsePrivacy .btn-default.active:focus, #collapsePrivacy .btn-default.active.focus, #collapsePrivacy .open > .btn-default.dropdown-toggle:hover, #collapsePrivacy .open > .btn-default.dropdown-toggle:focus, #collapsePrivacy .open > .btn-default.dropdown-toggle.focus {
  color: #fff;
  background-color: #005e83;
  border-color: #204d74; }

#collapsePrivacy .btn-default:active, #collapsePrivacy .btn-default.active, #collapsePrivacy .open > .btn-default.dropdown-toggle {
  color: #fff;
  background-color: #005e83;
  border-color: #204d74; }

#collapsePersonal .btn-default:active:hover, #collapsePersonal .btn-default:active:focus, #collapsePersonal .btn-default:active.focus, #collapsePersonal .btn-default.active:hover, #collapsePersonal .btn-default.active:focus, #collapsePersonal .btn-default.active.focus, #collapsePersonal .open > .btn-default.dropdown-toggle:hover, #collapsePersonal .open > .btn-default.dropdown-toggle:focus, #collapsePersonal .open > .btn-default.dropdown-toggle.focus {
  color: #fff;
  background-color: #005e83;
  border-color: #204d74; }

#collapsePersonal .btn-default:active, #collapsePersonal .btn-default.active, #collapsePersonal .open > .btn-default.dropdown-toggle {
  color: #fff;
  background-color: #005e83;
  border-color: #204d74; }

#registration .btn-default:active:hover, #registration .btn-default:active:focus, #registration .btn-default:active.focus, #registration .btn-default.active:hover, #registration .btn-default.active:focus, #registration .btn-default.active.focus, #registration .open > .btn-default.dropdown-toggle:hover, #registration .open > .btn-default.dropdown-toggle:focus, #registration .open > .btn-default.dropdown-toggle.focus {
  color: #fff;
  background-color: #005e83;
  border-color: #204d74; }

#registration .btn-default:active, #registration .btn-default.active, #registration .open > .btn-default.dropdown-toggle {
  color: #fff;
  background-color: #005e83;
  border-color: #204d74; }

/* This first block is sufficient for IE, Firefox, and Chrome. */
#collapsePrivacy br {
  display: block;
  margin-top: 8px;
  /* This is enough for Firefox and Chrome. It does nothing, though, for IE.*/
  vertical-align: top;
  /* This line and the next one are what do the trick for IE. */
  line-height: 24px; }

/* The following block is for Opera. */
#collapsePrivacy br:after {
  content: "";
  display: block;
  margin-top: 8px; }

#collapsePrivacy a {
  color: #005e83; }

.text-smaller {
  font-size: smaller; }

.datepicker {
  z-index: 99999 !important; }
